import React, { useCallback, useEffect, useState } from "react";
import { Box, Text, Select, Button, TextInput, Anchor, FileInput, Notification, rem, Radio, Group, Image, Checkbox } from "@mantine/core";
import { Carousel } from '@mantine/carousel';
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { appSelector } from "../../redux/app/selectors";
//import loader from "../../assets/img/loader.gif";
import {
  setProject
} from "../../redux/app/slice";
import ColorModal from "../Modals/ColorModal";
//import { StateNames } from "../../globals";
import { IconCheck } from '@tabler/icons-react';

const MainBoard = () => {
  const dispatch = useDispatch();
  const app = useSelector(appSelector);
  //const [projects, setProjects] = useState([{value: 1, label : "Project 1"}]);
  const [projects, setProjects] = useState([]);
  const [showColorModal, setShowColorModal] = useState(false);
  const [selectedColorType, setSelectedColorType] = useState("");
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedColorYear, setSelectedColorYear] = useState(false);
  const [selectedColorYearGroup, setSelectedColorYearGroup] = useState(false);
  const [updateYearColors, setUpdateYearColors] = useState(false);
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [colorType, setColorType] = useState("Gradient");
  const [maxValue, setMaxValue] = useState("");
  const [minValue, setMinValue] = useState("");
  const [maxColor, setMaxColor] = useState("");
  const [minColor, setMinColor] = useState("");
  const [yearMaxValue, setYearMaxValue] = useState("");
  const [yearMinValue, setYearMinValue] = useState("");
  const [yearMaxColor, setYearMaxColor] = useState("");
  const [yearMinColor, setYearMinColor] = useState("");
  const [minValue1, setMinValue1] = useState("");
  const [maxValue1, setMaxValue1] = useState("");
  const [groupColor1, setGroupColor1] = useState("");
  const [minValue2, setMinValue2] = useState("");
  const [maxValue2, setMaxValue2] = useState("");
  const [groupColor2, setGroupColor2] = useState("");
  const [minValue3, setMinValue3] = useState("");
  const [maxValue3, setMaxValue3] = useState("");
  const [groupColor3, setGroupColor3] = useState("");
  const [minValue4, setMinValue4] = useState("");
  const [maxValue4, setMaxValue4] = useState("");
  const [groupColor4, setGroupColor4] = useState("");
  const [minValue5, setMinValue5] = useState("");
  const [maxValue5, setMaxValue5] = useState("");
  const [groupColor5, setGroupColor5] = useState("");
  const [yearGroupsP, setYearGroupsP] = useState({});
  const [yearGroups, setYearGroups] = useState([]);
  const [yearGroupsMunP, setYearGroupsMunP] = useState({});
  const [yearGroupsMun, setYearGroupsMun] = useState([]);
  const [categoryColors, setCategoryColors] = useState([]);
  const [yearCategoryColors, setYearCategoryColors] = useState([]);
  const [yearCategoryColorsNew, setYearCategoryColorsNew] = useState([]);
  const [yearCategoryColorsMun, setYearCategoryColorsMun] = useState([]);
  const [yearCategoryColorsMunNew, setYearCategoryColorsMunNew] = useState([]);
  const [categoryColorInputs, setCategoryColorInputs] = useState("");
  const [categoryColorInputsP, setCategoryColorInputsP] = useState([]);
  const [categoryColorInputsN, setCategoryColorInputsN] = useState([]);
  const [categoryColorMunInputsP, setCategoryColorMunInputsP] = useState([]);
  const [categoryColorMunInputsN, setCategoryColorMunInputsN] = useState([]);
  const [yearMaxValueMun, setYearMaxValueMun] = useState("");
  const [yearMinValueMun, setYearMinValueMun] = useState("");
  const [yearMaxColorMun, setYearMaxColorMun] = useState("");
  const [yearMinColorMun, setYearMinColorMun] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [useDifferentColors, setUseDifferentColors] = useState(false);
  const [useDifferentColorsP, setUseDifferentColorsP] = useState([]);
  const [useDifferentColorsMun, setUseDifferentColorsMun] = useState(false);
  const [useDifferentColorsMunP, setUseDifferentColorsMunP] = useState([]);
  const [reload, setReload] = useState(false);
  const [images, setImages] = useState([]);
  const [imgRows, setImgRows] = useState("");
  const [imgSlides, setImgSlides] = useState("");
  const [years, setYears] = useState([]);
  const [rows, setRows] = useState([]);
  const [newYear, setNewYear] = useState(false);
  const [savedYears, setSavedYears] = useState(false);
  const [savedProject, setSavedProject] = useState(false);
  
  const openColorModal2 = useCallback(
    (colorType, color, yearInd=false, groupInd=false) => {
      setSelectedColorType(colorType);
      setSelectedColor(color);
      setSelectedColorYear(yearInd);
      setSelectedColorYearGroup(groupInd);
      setShowColorModal(true);
    },
    []
  );

  const onSuccessColorModal = useCallback((color) => {
    if (selectedColorYearGroup !== false) {
      if (selectedColorType === 'group_color_mun') {
        if (!yearGroupsMunP[selectedColorYear])
          yearGroupsMunP[selectedColorYear] = [];
        if (!yearGroupsMunP[selectedColorYear][selectedColorYearGroup])
          yearGroupsMunP[selectedColorYear][selectedColorYearGroup] = {};
        yearGroupsMunP[selectedColorYear][selectedColorYearGroup]['color'] = color;
        setYearGroupsMunP(yearGroupsMunP);
      }
      else if (selectedColorType === 'category_color_mun') {
        if (!yearCategoryColorsMun[selectedColorYear])
          yearCategoryColorsMun[selectedColorYear] = [];
        if (!yearCategoryColorsMun[selectedColorYear][selectedColorYearGroup])
          yearCategoryColorsMun[selectedColorYear][selectedColorYearGroup] = {};
        yearCategoryColorsMun[selectedColorYear][selectedColorYearGroup]['color'] = color;
        setYearCategoryColorsMun(yearCategoryColorsMun);  
      }
      else if (selectedColorType === 'category_color') {
        if (!yearCategoryColors[selectedColorYear])
          yearCategoryColors[selectedColorYear] = [];
        if (!yearCategoryColors[selectedColorYear][selectedColorYearGroup])
          yearCategoryColors[selectedColorYear][selectedColorYearGroup] = {};
        yearCategoryColors[selectedColorYear][selectedColorYearGroup]['color'] = color;
        setYearCategoryColors(yearCategoryColors);  
      }
      else {
        if (!yearGroupsP[selectedColorYear])
          yearGroupsP[selectedColorYear] = [];
        if (!yearGroupsP[selectedColorYear][selectedColorYearGroup])
          yearGroupsP[selectedColorYear][selectedColorYearGroup] = {};
        yearGroupsP[selectedColorYear][selectedColorYearGroup]['color'] = color;
        setYearGroupsP(yearGroupsP);
      }
      setUpdateYearColors(true);
    }
    else if (selectedColorYear !== false) {
      if (selectedColorType === 'min_color_mun') {
        years[selectedColorYear]['min_color_mun'] = color;
        setYears(years);   
      }
      else if (selectedColorType === 'max_color_mun') {
        years[selectedColorYear]['max_color_mun'] = color;  
        setYears(years);        
      }
      else if (selectedColorType === 'category_color_mun') {
        if (!yearCategoryColorsMunNew[selectedColorYear])
          yearCategoryColorsMunNew[selectedColorYear] = {};
        yearCategoryColorsMunNew[selectedColorYear]['color'] = color;
      }
      else if (selectedColorType === 'category_color') {
        if (!yearCategoryColorsNew[selectedColorYear])
          yearCategoryColorsNew[selectedColorYear] = {};
        yearCategoryColorsNew[selectedColorYear]['color'] = color;
      }
      else if (selectedColorType === 'group_color_mun') {
        yearGroupsMun[selectedColorYear]['color'] = color;
      }
      else if (selectedColorType === 'group_color') {
        yearGroups[selectedColorYear]['color'] = color;
      }
      else if (selectedColorType === 'min_color') {
        years[selectedColorYear]['min_color'] = color;
        setYears(years);   
      }
      else {
        years[selectedColorYear]['max_color'] = color;  
        setYears(years);        
      }      
      setUpdateYearColors(true);
    }
    else if (selectedColorType.match('category_color')) {
      const newCategoryColors = [...categoryColors]; 
      const key = selectedColorType.split('_')[2];
      newCategoryColors[key].color = color; 
      setCategoryColors(newCategoryColors);
    }
    else if (selectedColorType === 'group_color1')
      setGroupColor1(color);
    else if (selectedColorType === 'group_color2')
      setGroupColor2(color);
    else if (selectedColorType === 'group_color3')
      setGroupColor3(color);
    else if (selectedColorType === 'group_color4')
      setGroupColor4(color);
    else if (selectedColorType === 'group_color5')
      setGroupColor5(color);
    else if (selectedColorType === 'year_min_color')
      setYearMinColor(color);
    else if (selectedColorType === 'year_max_color')
      setYearMaxColor(color);
    else if (selectedColorType === 'year_min_color_mun')
      setYearMinColorMun(color);
    else if (selectedColorType === 'year_max_color_mun')
      setYearMaxColorMun(color);
    else if (selectedColorType === 'min_color')
      setMinColor(color);
    else
      setMaxColor(color);
    //setSelectedColor(color);
    //setReload(true);
  }, [selectedColorType, selectedColorYear, selectedColorYearGroup, categoryColors, yearCategoryColors, yearCategoryColorsMun, yearCategoryColorsNew, yearCategoryColorsMunNew, yearGroups, yearGroupsMun, yearGroupsP, yearGroupsMunP, years]);

  const onCloseColorModal = useCallback(() => {
    setSelectedColor("");
    setShowColorModal(false);
  }, []);
  
  const changeProject = useCallback(
    (e) => {
      console.log(e);
      dispatch(setProject(e));
    },
    [dispatch]
  );
  
  const addNewProject = useCallback(
    () => {
      dispatch(setProject(0));
      setName("");
      setTitle("");
      setColorType("Gradient");
      setMaxValue("");
      setMinValue("");
      setMaxColor("");
      setMinColor("");
      setMinValue1("");
      setMinValue2("");
      setMinValue3("");
      setMinValue4("");
      setMinValue5("");
      setMaxValue1("");
      setMaxValue2("");
      setMaxValue3("");
      setMaxValue4("");
      setMaxValue5("");
      setGroupColor1("");
      setGroupColor2("");
      setGroupColor3("");
      setGroupColor4("");
      setGroupColor5("");
      setShowPopup(false);
      setImages([]);
      setYears([]);     
      let newCategoryColors = [];
      for (let i = 0; i < 10; i++) {
        newCategoryColors[i] = {};
        newCategoryColors[i].value = '';   
        newCategoryColors[i].color = '';  
      }
      setCategoryColors(newCategoryColors);
    },
    [dispatch]
  );
  
  const loadProjectYears = useCallback(
    (project_id) => {
        //load project years
        let url =
          process.env.REACT_APP_BASE_URL +
          "/api/projectYears/" + project_id;
          console.log(url);
        axios.get(url).then(function (response) {
          console.log(response);
          if (response.data && response.data.data) {
            setYears(response.data.data);
            
            let dcolors = [];
            response.data.data.forEach(ydata => {
              dcolors.push(ydata.use_different_colors);
            });
            console.log(dcolors);
            setUseDifferentColorsP(dcolors);
            
            let dmcolors = [];
            response.data.data.forEach(ydata => {
              dmcolors.push(ydata.use_different_colors_mun);
            });
            setUseDifferentColorsMunP(dmcolors);
            
            const el = document.getElementsByClassName('mantine-CloseButton-root');
            for (let i=0;i<el.length; i++) {
                el[i].click();
            }
          }
        });
    },
    []
  );
  
  const loadProjects = useCallback(
    () => {        
        //get all projects
        let url =
          process.env.REACT_APP_BASE_URL +
          "/api/projects/?user_id=" + localStorage.getItem('user_id');
        console.log(url);

        axios.get(url).then(function (response) {
          console.log(response);
          if (response.data && response.data.data) {
            let p = [];
            let latest = 0;
            for (const pj of response.data.data) {
              let d = {}
              d.value = pj.id;
              d.label = pj.name;
              p.push(d);
              if (pj.id > latest)
                latest = pj.id;
            }
            
            console.log(p);
            setProjects(p);
            //set latest project        
            dispatch(setProject(latest));  

            loadProjectYears(latest);
          }
        });
    },
    [dispatch, loadProjectYears]
  );
  
  const saveProject = useCallback(
    () => {
      //save project
      let url = '';
      let new_project = false;
      if (app.project === 0) {
        url =
        process.env.REACT_APP_BASE_URL +
        "/api/saveProject";
        new_project = true;
      }
      else {
        url =
        process.env.REACT_APP_BASE_URL +
        "/api/saveProject/" + app.project;
      }
      
      const formData = new FormData();
      formData.append('name', name);
      formData.append('title', title);
      formData.append('colorType', colorType);
      formData.append('maxValue', maxValue);
      formData.append('minValue', minValue);
      formData.append('maxColor', maxColor);
      formData.append('minColor', minColor);
      formData.append('minValue1', minValue1);
      formData.append('maxValue1', maxValue1);
      formData.append('groupColor1', groupColor1);
      formData.append('minValue2', minValue2);
      formData.append('maxValue2', maxValue2);
      formData.append('groupColor2', groupColor2);
      formData.append('minValue3', minValue3);
      formData.append('maxValue3', maxValue3);
      formData.append('groupColor3', groupColor3);
      formData.append('minValue4', minValue4);
      formData.append('maxValue4', maxValue4);
      formData.append('groupColor4', groupColor4);
      formData.append('minValue5', minValue5);
      formData.append('maxValue5', maxValue5);
      formData.append('groupColor5', groupColor5);
      formData.append('showPopup', showPopup);
      formData.append('user_id', localStorage.getItem('user_id'));
      const images_len = document.getElementById('images').parentElement.nextElementSibling.files.length;
      if (images_len > 0) {
        for (let i = 0; i < images_len; i++) {
          formData.append('images[]', document.getElementById('images').parentElement.nextElementSibling.files[i]);
        }
      }
      for (let i = 0; i < categoryColors.length; i++) {
        if (categoryColors[i].value.trim()) {
          formData.append('categoryValue[]', categoryColors[i].value);
          formData.append('categoryColor[]', categoryColors[i].color);
        }
      }
      
      console.log(formData);
      axios.post(url, formData).then(function (response) {
        console.log('aaaa');
        console.log(response);
        if (new_project) {
          loadProjects();
        }
        else {
          setReload(true);
        }
        
        const el = document.getElementsByClassName('mantine-CloseButton-root');
        for (let i=0;i<el.length; i++) {
            el[i].click();
        }
        
        setSavedProject(true);
        setTimeout(() => { setSavedProject(false); }, 3000);
      });      
    },
    [loadProjects, app.project, name, title, colorType, maxValue, minValue, maxColor, minColor, minValue1, maxValue1, groupColor1, minValue2, maxValue2, groupColor2, minValue3, maxValue3, groupColor3, minValue4, maxValue4, groupColor4, minValue5, maxValue5, groupColor5, categoryColors, showPopup]
  );
  
  const deleteProject = useCallback(() => {
    if (window.confirm('Are you sure to delete this project?')) {
      let url = process.env.REACT_APP_BASE_URL +
        "/api/deleteProject/" + app.project;
      axios.post(url, {}).then(function (response) {
        loadProjects();
      });
    }
  }, [app.project, loadProjects]);
  
  const deleteImg = useCallback((img_id) => {
    if (window.confirm('Are you sure to delete this image?')) {
      let url = process.env.REACT_APP_BASE_URL +
        "/api/deleteImg/" + app.project;
      axios.post(url, {img_id:img_id}).then(function (response) {
        console.log(response);
        console.log('ddd');
        setReload(true);
      });
    }
  }, [app.project]);
  
  const deleteFile = useCallback((type, year_id) => {
    if (window.confirm('Are you sure to delete this file?')) {
      let url = process.env.REACT_APP_BASE_URL +
        "/api/deleteFile/" + app.project;
      axios.post(url, {type:type, year_id:year_id}).then(function (response) {
        loadProjects();
      });
    }
  }, [app.project, loadProjects]);
  
  const deleteYear = useCallback((year_id) => {
    if (window.confirm('Are you sure to delete this year?')) {
      let url = process.env.REACT_APP_BASE_URL +
        "/api/deleteYear/" + app.project;
      axios.post(url, {year_id:year_id}).then(function (response) {
        //loadProjects();
        setReload(true);
      });
    }
  }, [app.project/*, loadProjects*/]);
  
  const deleteNewYear = useCallback(() => {
    setNewYear(false);
  }, []);
  
  const saveYears = useCallback(
    () => {
      let url = process.env.REACT_APP_BASE_URL +
        "/api/saveYears/" + app.project;  
        
      const formData = new FormData();
      
      let ylength = years.length;
      if (ylength === 0) {
        ylength = 1;
      }
      else if (newYear) {
        ylength = ylength + 1;
      }
      
      formData.append("length", ylength);
      for (let i = 0; i < ylength; i++) {
         let year_id = document.getElementById('year_id_' + i).value
          
         console.log('yyyyy');
         console.log(year_id);
         console.log(document.getElementById('year_' + i).value);
         console.log(document.getElementById('state_csv_' + i).parentElement.nextElementSibling.files);
         
         if (!document.getElementById('year_' + i).value) {
           alert('Year must be entered.');
           return false;
         }
         
         formData.append("year_id_" + i, year_id);
         formData.append("year_" + i, document.getElementById('year_' + i).value);
         if (document.getElementById('different_colors_' + i) && document.getElementById('different_colors_' + i).checked) {
           formData.append("different_colors_" + i, document.getElementById('different_colors_' + i).value);
         }
         if (document.getElementById('max_color_' + i)) {
           formData.append("max_color_" + i, document.getElementById('max_color_' + i).value);
         }
         if (document.getElementById('max_value_' + i)) {
           formData.append("max_value_" + i, document.getElementById('max_value_' + i).value);
         }
         if (document.getElementById('min_color_' + i)) {
           formData.append("min_color_" + i, document.getElementById('min_color_' + i).value);
         }
         if (document.getElementById('min_value_' + i)) {
           formData.append("min_value_" + i, document.getElementById('min_value_' + i).value);
         }         
         if (document.getElementById('min_value1_' + i)) {
           formData.append("min_value1_" + i, document.getElementById('min_value1_' + i).value);
         }
         if (document.getElementById('max_value1_' + i)) {
           formData.append("max_value1_" + i, document.getElementById('max_value1_' + i).value);
         }
         if (document.getElementById('group_color1_' + i)) {
           formData.append("group_color1_" + i, document.getElementById('group_color1_' + i).value);
         }
         if (document.getElementById('min_value2_' + i)) {
           formData.append("min_value2_" + i, document.getElementById('min_value2_' + i).value);
         }
         if (document.getElementById('max_value2_' + i)) {
           formData.append("max_value2_" + i, document.getElementById('max_value2_' + i).value);
         }
         if (document.getElementById('group_color2_' + i)) {
           formData.append("group_color2_" + i, document.getElementById('group_color2_' + i).value);
         }
         if (document.getElementById('min_value3_' + i)) {
           formData.append("min_value3_" + i, document.getElementById('min_value3_' + i).value);
         }
         if (document.getElementById('max_value3_' + i)) {
           formData.append("max_value3_" + i, document.getElementById('max_value3_' + i).value);
         }
         if (document.getElementById('group_color3_' + i)) {
           formData.append("group_color3_" + i, document.getElementById('group_color3_' + i).value);
         }
         if (document.getElementById('min_value4_' + i)) {
           formData.append("min_value4_" + i, document.getElementById('min_value4_' + i).value);
         }
         if (document.getElementById('max_value4_' + i)) {
           formData.append("max_value4_" + i, document.getElementById('max_value4_' + i).value);
         }
         if (document.getElementById('group_color4_' + i)) {
           formData.append("group_color4_" + i, document.getElementById('group_color4_' + i).value);
         }
         if (document.getElementById('min_value5_' + i)) {
           formData.append("min_value5_" + i, document.getElementById('min_value5_' + i).value);
         }
         if (document.getElementById('max_value5_' + i)) {
           formData.append("max_value5_" + i, document.getElementById('max_value5_' + i).value);
         }
         if (document.getElementById('group_color5_' + i)) {
           formData.append("group_color5_" + i, document.getElementById('group_color5_' + i).value);
         }
         
         for (let j = 0; j < 10; j++) {
           if (document.getElementById('category_color_' + j + '_' + year_id) && document.getElementById('category_color_' + j + '_' + year_id).value) {
             formData.append('category_color_' + j + '_' + year_id, document.getElementById('category_color_' + j + '_' + year_id).value);
           }
           if (document.getElementById('category_value_' + j + '_' + year_id) && document.getElementById('category_value_' + j + '_' + year_id).value) {
             formData.append('category_value_' + j + '_' + year_id, document.getElementById('category_value_' + j + '_' + year_id).value);
           }
         }          

         for (let j = 0; j < 10; j++) {
           if (document.getElementById('category_color_' + j) && document.getElementById('category_color_' + j).value) {
             formData.append('category_color_' + j, document.getElementById('category_color_' + j).value);
           }
           if (document.getElementById('category_value_' + j) && document.getElementById('category_value_' + j).value) {
             formData.append('category_value_' + j, document.getElementById('category_value_' + j).value);
           }
         }
         
         if (document.getElementById('state_csv_' + i).parentElement.nextElementSibling.files.length > 0) {
           formData.append("state_csv_" + i, document.getElementById('state_csv_' + i).parentElement.nextElementSibling.files[0]);
         }
         if (document.getElementById('district_csv_' + i).parentElement.nextElementSibling.files.length > 0) {
           formData.append("district_csv_" + i, document.getElementById('district_csv_' + i).parentElement.nextElementSibling.files[0]);
         }
         if (document.getElementById('municipal_csv_' + i).parentElement.nextElementSibling.files.length > 0) {
           formData.append("municipal_csv_" + i, document.getElementById('municipal_csv_' + i).parentElement.nextElementSibling.files[0]);
         }
         
         if (document.getElementById('different_colors_mun_' + i) && document.getElementById('different_colors_mun_' + i).checked) {
           formData.append("different_colors_mun_" + i, document.getElementById('different_colors_mun_' + i).value);
         }
         if (document.getElementById('max_color_mun_' + i)) {
           formData.append("max_color_mun_" + i, document.getElementById('max_color_mun_' + i).value);
         }
         if (document.getElementById('max_value_mun_' + i)) {
           formData.append("max_value_mun_" + i, document.getElementById('max_value_mun_' + i).value);
         }
         if (document.getElementById('min_color_mun_' + i)) {
           formData.append("min_color_mun_" + i, document.getElementById('min_color_mun_' + i).value);
         }
         if (document.getElementById('min_value_mun_' + i)) {
           formData.append("min_value_mun_" + i, document.getElementById('min_value_mun_' + i).value);
         }  
         if (document.getElementById('min_value1_mun_' + i)) {
           formData.append("min_value1_mun_" + i, document.getElementById('min_value1_mun_' + i).value);
         }
         if (document.getElementById('max_value1_mun_' + i)) {
           formData.append("max_value1_mun_" + i, document.getElementById('max_value1_mun_' + i).value);
         }
         if (document.getElementById('group_color1_mun_' + i)) {
           formData.append("group_color1_mun_" + i, document.getElementById('group_color1_mun_' + i).value);
         }
         if (document.getElementById('min_value2_mun_' + i)) {
           formData.append("min_value2_mun_" + i, document.getElementById('min_value2_mun_' + i).value);
         }
         if (document.getElementById('max_value2_mun_' + i)) {
           formData.append("max_value2_mun_" + i, document.getElementById('max_value2_mun_' + i).value);
         }
         if (document.getElementById('group_color2_mun_' + i)) {
           formData.append("group_color2_mun_" + i, document.getElementById('group_color2_mun_' + i).value);
         }
         if (document.getElementById('min_value3_mun_' + i)) {
           formData.append("min_value3_mun_" + i, document.getElementById('min_value3_mun_' + i).value);
         }
         if (document.getElementById('max_value3_mun_' + i)) {
           formData.append("max_value3_mun_" + i, document.getElementById('max_value3_mun_' + i).value);
         }
         if (document.getElementById('group_color3_mun_' + i)) {
           formData.append("group_color3_mun_" + i, document.getElementById('group_color3_mun_' + i).value);
         }
         if (document.getElementById('min_value4_mun_' + i)) {
           formData.append("min_value4_mun_" + i, document.getElementById('min_value4_mun_' + i).value);
         }
         if (document.getElementById('max_value4_mun_' + i)) {
           formData.append("max_value4_mun_" + i, document.getElementById('max_value4_mun_' + i).value);
         }
         if (document.getElementById('group_color4_mun_' + i)) {
           formData.append("group_color4_mun_" + i, document.getElementById('group_color4_mun_' + i).value);
         }
         if (document.getElementById('min_value5_mun_' + i)) {
           formData.append("min_value5_mun_" + i, document.getElementById('min_value5_mun_' + i).value);
         }
         if (document.getElementById('max_value5_mun_' + i)) {
           formData.append("max_value5_mun_" + i, document.getElementById('max_value5_mun_' + i).value);
         }
         if (document.getElementById('group_color5_mun_' + i)) {
           formData.append("group_color5_mun_" + i, document.getElementById('group_color5_mun_' + i).value);
         }
         
         for (let j = 0; j < 10; j++) {
           if (document.getElementById('category_color_mun_' + j + '_' + year_id) && document.getElementById('category_color_mun_' + j + '_' + year_id).value) {
             formData.append('category_color_mun_' + j + '_' + year_id, document.getElementById('category_color_mun_' + j + '_' + year_id).value);
           }
           if (document.getElementById('category_value_mun_' + j + '_' + year_id) && document.getElementById('category_value_mun_' + j + '_' + year_id).value) {
             formData.append('category_value_mun_' + j + '_' + year_id, document.getElementById('category_value_mun_' + j + '_' + year_id).value);
           }
         }          

         for (let j = 0; j < 10; j++) {
           if (document.getElementById('category_color_mun_' + j) && document.getElementById('category_color_mun_' + j).value) {
             formData.append('category_color_mun_' + j, document.getElementById('category_color_mun_' + j).value);
           }
           if (document.getElementById('category_value_mun_' + j) && document.getElementById('category_value_mun_' + j).value) {
             formData.append('category_value_mun_' + j, document.getElementById('category_value_mun_' + j).value);
           }
         }
      }
      
      formData.append('color_type', colorType);
      
      console.log(formData);
      
      axios.post(url, formData).then(function (response) {
        console.log(response);
        setNewYear(false);
        setUseDifferentColors(false);
        //loadProjectYears(app.project);  
        setReload(true);
        setSavedYears(true);
        setTimeout(() => { setSavedYears(false); }, 3000);
      });
    },
    [app.project, years/*, loadProjectYears*/, newYear, colorType]
  );
  
  const addNewYear = useCallback(
    () => {
      setNewYear(true);
    },
    []
  );
  
  const setUseDifferentColorsPInd = useCallback(
    (value, i) => {
      if (updateYearColors)
        setUpdateYearColors(false);
      const dcolors = useDifferentColorsP;
      dcolors[i] = value;
      console.log(dcolors);
      setUseDifferentColorsP(dcolors);
    },
    [useDifferentColorsP, updateYearColors]
  );
  
  const setUseDifferentColorsMunPInd = useCallback(
    (value, i) => {
      if (updateYearColors)
        setUpdateYearColors(false);
      const dmcolors = useDifferentColorsMunP;
      dmcolors[i] = value;
      setUseDifferentColorsMunP(dmcolors);
    },
    [useDifferentColorsMunP, updateYearColors]
  );
   
  useEffect(() => {
    console.log('init');
    loadProjects();
  }, [loadProjects]);
  
  useEffect(() => {    
    if (reload) {
        console.log('reload');
        setReload(false);
        //get all projects
        let url =
          process.env.REACT_APP_BASE_URL +
          "/api/projects/?user_id=" + localStorage.getItem('user_id');
        console.log(url);

        axios.get(url).then(function (response) {
          if (response.data && response.data.data) {
            let p = [];
            let latest = 0;
            for (const pj of response.data.data) {
              let d = {}
              d.value = pj.id;
              d.label = pj.name;
              p.push(d);
              if (pj.id > latest)
                latest = pj.id;
            }
            setProjects(p);
          }
        });
    }
  }, [reload]);
  
  useEffect(() => {    
    if (app.project) {
      //get project by id
      let url =
        process.env.REACT_APP_BASE_URL +
        "/api/getProject/" + app.project;
      console.log(url);
        
      axios.get(url).then(function (response) {
        console.log('llll');
        console.log(response);
        if (response.data && response.data.data && response.data.data[0]) {
          const min_value1 = response.data.data[0].groups[0] ? response.data.data[0].groups[0].min_value : '';
          const max_value1 = response.data.data[0].groups[0] ? response.data.data[0].groups[0].max_value : '';
          const group_color1 = response.data.data[0].groups[0] ? response.data.data[0].groups[0].color : '';
          const min_value2 = response.data.data[0].groups[1] ? response.data.data[0].groups[1].min_value : '';
          const max_value2 = response.data.data[0].groups[1] ? response.data.data[0].groups[1].max_value : '';
          const group_color2 = response.data.data[0].groups[1] ? response.data.data[0].groups[1].color : '';
          const min_value3 = response.data.data[0].groups[2] ? response.data.data[0].groups[2].min_value : '';
          const max_value3 = response.data.data[0].groups[2] ? response.data.data[0].groups[2].max_value : '';
          const group_color3 = response.data.data[0].groups[2] ? response.data.data[0].groups[2].color : '';
          const min_value4 = response.data.data[0].groups[3] ? response.data.data[0].groups[3].min_value : '';
          const max_value4 = response.data.data[0].groups[3] ? response.data.data[0].groups[3].max_value : '';
          const group_color4 = response.data.data[0].groups[3] ? response.data.data[0].groups[3].color : '';
          const min_value5 = response.data.data[0].groups[4] ? response.data.data[0].groups[4].min_value : '';
          const max_value5 = response.data.data[0].groups[4] ? response.data.data[0].groups[4].max_value : '';
          const group_color5 = response.data.data[0].groups[4] ? response.data.data[0].groups[4].color : '';
          const year_groups = response.data.data[0].year_groups ? response.data.data[0].year_groups : {};
          const year_groups_mun = response.data.data[0].year_groups_mun ? response.data.data[0].year_groups_mun : {};
          
          let category_colors = response.data.data[0].category ? response.data.data[0].category : '';
          const newCategoryColors = []; 
          for (let i = 0; i < 10; i++) {
            newCategoryColors[i] = {};
            if (category_colors[i]) {
              newCategoryColors[i].value = category_colors[i].value;   
              newCategoryColors[i].color = category_colors[i].color;  
            }
            else {
              newCategoryColors[i].value = '';   
              newCategoryColors[i].color = '';  
            }
          }
          setCategoryColors(newCategoryColors);
          
          let year_category_colors = response.data.data[0].year_category ? response.data.data[0].year_category : '';
          setYearCategoryColors(year_category_colors);
          
          let year_category_colors_mun = response.data.data[0].year_category_mun ? response.data.data[0].year_category_mun : '';
          setYearCategoryColorsMun(year_category_colors_mun);
          
          const imgs = response.data.data[0].images ? response.data.data[0].images : [];
          console.log(imgs);
          console.log(year_groups);
          console.log('mmmm');          
            
          setName(response.data.data[0].name);
          setTitle(response.data.data[0].title);
          setColorType(response.data.data[0].color_type);
          setMaxValue(response.data.data[0].max_value);
          setMinValue(response.data.data[0].min_value);
          setMaxColor(response.data.data[0].max_color);
          setMinColor(response.data.data[0].min_color);
          setMinValue1(min_value1);
          setMaxValue1(max_value1);
          setGroupColor1(group_color1);
          setMinValue2(min_value2);
          setMaxValue2(max_value2);
          setGroupColor2(group_color2);
          setMinValue3(min_value3);
          setMaxValue3(max_value3);
          setGroupColor3(group_color3);
          setMinValue4(min_value4);
          setMaxValue4(max_value4);
          setGroupColor4(group_color4);
          setMinValue5(min_value5);
          setMaxValue5(max_value5);
          setGroupColor5(group_color5);
          setYearGroupsP(year_groups);
          setYearGroupsMunP(year_groups_mun);
          setShowPopup(response.data.data[0].show_popup);
          setImages(imgs);
        }
      });     
      
      loadProjectYears(app.project);
    }
    else {
      setName("");
      setTitle("");
      setColorType("Gradient");
      setMaxValue("");
      setMinValue("");
      setMaxColor("");
      setMinColor("");
      setMinValue1("");
      setMaxValue1("");
      setGroupColor1("");
      setMinValue2(""); 
      setMaxValue2("");
      setGroupColor2("");
      setMinValue3("");
      setMaxValue3("");
      setGroupColor3("");
      setMinValue4("");
      setMaxValue4("");
      setGroupColor4("");
      setMinValue5("");
      setMaxValue5("");
      setGroupColor5("");
      setYearGroupsP({});
      setYearGroupsMunP({});
      setShowPopup(false);
      setImages([]);
    }
  }, [app.project, reload, loadProjectYears]);
  
  useEffect(() => {
    //const storages = { ...localStorage };
    //console.log(storages);
    console.log('app:');
    console.log(app);
  }, [app]);
  
  useEffect(() => { 
    const components = [];
    for (let i = 0; i < 10; i++) {
      components.push({value: '', color: ''});
    }
    setCategoryColors(components);
  }, []);
  
  useEffect(() => {   
    console.log(categoryColors);
    console.log('aaaa');
    if (categoryColors.length) {  
      const components = [];
      for (let i = 0; i < 10; i++) {
        let k = i + 1;
        components.push(
            <Box key={'category_' + i} style={{ display: "flex", marginTop: "5px", marginBottom: "5px" }}>              
              <TextInput
                label={'Value ' + k + ':'}
                placeholder="e.g. Apple, Banana..."
                style={{width:"170px", marginRight: "12px"}}
                value={categoryColors[i].value}
                onChange={(e) => { 
                  const newCategoryColors = [...categoryColors]; 
                  newCategoryColors[i].value = e.target.value; 
                  setCategoryColors(newCategoryColors); 
                }}
              />                  
              <TextInput
                label="Color:"
                placeholder="Click to select"
                style={{width:"130px"}}
                styles={{input: {backgroundColor:categoryColors[i].color}}}
                value={categoryColors[i].color}
                onClick={(e) =>
                    openColorModal2(
                      "category_color_" + i,
                      categoryColors[i].color
                    )
                }
                onChange={(e) => {}}
              />
            </Box>
        );
      }
      setCategoryColorInputs(components);
      console.log('cccccc');
      console.log(categoryColors);
    }
  }, [categoryColors, openColorModal2]);
  
  useEffect(() => {   
      const components = [];
      for (let i = 0; i < 10; i++) {
        let k = i + 1;
        components.push(
            <Box key={'categoryn_' + i} style={{ display: "flex", marginTop: "5px", marginBottom: "5px" }}>              
              <TextInput
                label={'Value ' + k + ':'}
                placeholder="e.g. Apple, Banana..."
                style={{width:"170px", marginRight: "12px"}}
                value={yearCategoryColorsNew[i] ? yearCategoryColorsNew[i].value : ''}
                onChange={(e) => { 
                  if (!yearCategoryColorsNew[i])
                    yearCategoryColorsNew[i] = {};
                  yearCategoryColorsNew[i].value = e.target.value; 
                  setYearCategoryColorsNew(yearCategoryColorsNew); 
                  setUpdateYearColors(!updateYearColors);
                }}
                id={'category_value_' + i}
              />                  
              <TextInput
                label="Color:"
                placeholder="Click to select"
                style={{width:"130px"}}
                styles={{input: {backgroundColor:yearCategoryColorsNew[i] ? yearCategoryColorsNew[i].color : ''}}}
                value={yearCategoryColorsNew[i] ? yearCategoryColorsNew[i].color : ''}
                onClick={(e) =>
                    openColorModal2(
                      "category_color",
                      yearCategoryColorsNew[i] ? yearCategoryColorsNew[i].color : '',
                      i
                    )
                }
                onChange={(e) => {}}
                id={'category_color_' + i}
              />
            </Box>
        );
      }
      setCategoryColorInputsN(components);
  }, [yearCategoryColorsNew, openColorModal2, updateYearColors]);
  
  useEffect(() => {   
      const components = [];
      for (let i = 0; i < 10; i++) {
        let k = i + 1;
        components.push(
            <Box key={'categorynm_' + i} style={{ display: "flex", marginTop: "5px", marginBottom: "5px" }}>              
              <TextInput
                label={'Value ' + k + ':'}
                placeholder="e.g. Apple, Banana..."
                style={{width:"170px", marginRight: "12px"}}
                value={yearCategoryColorsMunNew[i] ? yearCategoryColorsMunNew[i].value : ''}
                onChange={(e) => { 
                  if (!yearCategoryColorsMunNew[i])
                    yearCategoryColorsMunNew[i] = {};
                  yearCategoryColorsMunNew[i].value = e.target.value; 
                  setYearCategoryColorsMunNew(yearCategoryColorsMunNew); 
                  setUpdateYearColors(!updateYearColors);
                }}
                id={'category_value_mun_' + i}
              />                  
              <TextInput
                label="Color:"
                placeholder="Click to select"
                style={{width:"130px"}}
                styles={{input: {backgroundColor:yearCategoryColorsMunNew[i] ? yearCategoryColorsMunNew[i].color : ''}}}
                value={yearCategoryColorsMunNew[i] ? yearCategoryColorsMunNew[i].color : ''}
                onClick={(e) =>
                    openColorModal2(
                      "category_color_mun",
                      yearCategoryColorsMunNew[i] ? yearCategoryColorsMunNew[i].color : '',
                      i
                    )
                }
                onChange={(e) => {}}
                id={'category_color_mun_' + i}
              />
            </Box>
        );
      }
      setCategoryColorMunInputsN(components);
  }, [yearCategoryColorsMunNew, openColorModal2, updateYearColors]);
  
  useEffect(() => {   
    console.log(yearCategoryColors);
    console.log('aaaayear');
    let components = {};
    Object.entries(yearCategoryColors).forEach(function(cat) {
      let year_id = cat[0];
      let cat_color = cat[1];
      console.log(year_id);
      console.log(cat_color);      
      components[year_id] = [];
      for (let i = 0; i < 10; i++) {
        let ccolor = cat_color[i];
        let k = i + 1;
        components[year_id].push(
            <Box key={'categoryp_' + i} style={{ display: "flex", marginTop: "5px", marginBottom: "5px" }}>              
              <TextInput
                label={'Value ' + k + ':'}
                placeholder="e.g. Apple, Banana..."
                style={{width:"170px", marginRight: "12px"}}
                value={ccolor ? ccolor.value : ''}
                onChange={(e) => { 
                  if (!yearCategoryColors[year_id])
                    yearCategoryColors[year_id] = [];
                  if (!yearCategoryColors[year_id][i])
                    yearCategoryColors[year_id][i] = {};
                  yearCategoryColors[year_id][i].value = e.target.value; 
                  setYearCategoryColors(yearCategoryColors); 
                  setUpdateYearColors(!updateYearColors);
                }}
                id={'category_value_' + i + '_' + year_id}
              />                  
              <TextInput
                label="Color:"
                placeholder="Click to select"
                style={{width:"130px"}}
                styles={{input: {backgroundColor:ccolor ? ccolor.color : ''}}}
                value={ccolor ? ccolor.color : ''}
                onClick={(e) =>
                    openColorModal2(
                      "category_color",
                      ccolor ? ccolor.color : '',
                      year_id,
                      i
                    )
                }
                onChange={(e) => {}}
                id={'category_color_' + i + '_' + year_id}
              />
            </Box>
        );
      }
    });
    setCategoryColorInputsP(components);
  }, [yearCategoryColors, openColorModal2, updateYearColors]);
  
  useEffect(() => {   
    console.log('xxxxvvvvv');
    console.log(yearCategoryColorsMun);
    let components = {};
    Object.entries(yearCategoryColorsMun).forEach(function(cat) {
      let year_id = cat[0];
      let cat_color = cat[1]; 
      components[year_id] = [];
      for (let i = 0; i < 10; i++) {
        let ccolor = cat_color[i];
        let k = i + 1;
        components[year_id].push(
            <Box key={'categorypm_' + i} style={{ display: "flex", marginTop: "5px", marginBottom: "5px" }}>              
              <TextInput
                label={'Value ' + k + ':'}
                placeholder="e.g. Apple, Banana..."
                style={{width:"170px", marginRight: "12px"}}
                value={ccolor ? ccolor.value : ''}
                onChange={(e) => { 
                  if (!yearCategoryColorsMun[year_id])
                    yearCategoryColorsMun[year_id] = [];
                  if (!yearCategoryColorsMun[year_id][i])
                    yearCategoryColorsMun[year_id][i] = {};
                  yearCategoryColorsMun[year_id][i].value = e.target.value; 
                  setYearCategoryColorsMun(yearCategoryColorsMun); 
                  setUpdateYearColors(!updateYearColors);
                }}
                id={'category_value_mun_' + i + '_' + year_id}
              />                  
              <TextInput
                label="Color:"
                placeholder="Click to select"
                style={{width:"130px"}}
                styles={{input: {backgroundColor:ccolor ? ccolor.color : ''}}}
                value={ccolor ? ccolor.color : ''}
                onClick={(e) =>
                    openColorModal2(
                      "category_color_mun",
                      ccolor ? ccolor.color : '',
                      year_id,
                      i
                    )
                }
                onChange={(e) => {}}
                id={'category_color_mun_' + i + '_' + year_id}
              />
            </Box>
        );
      }
    });
    setCategoryColorMunInputsP(components);
  }, [yearCategoryColorsMun, openColorModal2, updateYearColors]);
  
  useEffect(() => {
      const imgs = images
      .map((el, k) => (
        <Box key={'img_' + k} style={{display:'flex', position:'relative'}}>
            <Image
              style={{marginRight:'15px', cursor:'pointer'}}              
              height={'100px'}
              width={'auto'}
              src={process.env.REACT_APP_CHRONOS_URL + '/csv/' + el.name}
              onClick={() => {document.getElementById('slides').style.visibility = 'visible';}}
            />
            <div style={{color:'red', position:'absolute', right:17, top:1, fontFamily:'Arial', cursor:'pointer'}} onClick={() => deleteImg(el.id)}>
              <b>X</b>
            </div>
        </Box>
      ));      
      
      const imgSlides = images
      .map((el, k) => (
        <Carousel.Slide key={'slide_' + k}>
          <Image        
            height={'100%'}
            width={'100%'}
            src={process.env.REACT_APP_CHRONOS_URL + '/csv/' + el.name}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
        </Carousel.Slide>
      ));   
      
      setImgRows(imgs);
      setImgSlides(imgSlides);
  }, [images, deleteImg]);
  
  useEffect(() => {
    if (updateYearColors)
      setUpdateYearColors(false);
    const yrows = years
      .map((el, k) => (
        <Box key={'box_' + k} style={{ marginTop: "20px", paddingTop: "20px", borderTop: "solid 1px #999999" }}>
          <Box style={{display: "flex", width:"100%"}}>
            <Text
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "15px",
                fontSize: "15px"
              }}
            >
              <b>Year:</b>
            </Text>
            <TextInput
              className={"yearInput"}
              placeholder="Year"
              style={{width:"200px"}}
              defaultValue={el.year}
              id={'year_' + k}
              key={el.year}
            />
            <Anchor href="javscript:void(0);" onClick={() => deleteYear(el.id)} style={{color:"red", marginTop:"7px", marginLeft:"25px", textDecoration:"underline"}}>Delete</Anchor>
            <input type="hidden" id={'year_id_' + k} value={el.id} />
          </Box>
          <Box style={{ display: "none", marginTop: "25px", marginBottom: "1px" }}>
            <Text
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "15px",
                fontSize: "15px"
              }}
            >
              Use Different Colors:
            </Text>
            <Checkbox
              label=""
              checked={useDifferentColorsP[k]}
              onChange={(e) => {setUseDifferentColorsPInd(!useDifferentColorsP[k], k); setUpdateYearColors(!updateYearColors);}}
              value="Y"
              id={'different_colors_' + k}
            />
          </Box>
          {(useDifferentColorsP[k] && (colorType === 'Gradient' || !colorType)) ? 
          <Box>
            <Box style={{ display: "flex", marginTop: "10px" }}>
              <TextInput
                label="Max. Color:"
                placeholder="Click to select"
                style={{width:"130px"}}
                styles={{input: {backgroundColor:years[k]['max_color']}}}
                value={years[k]['max_color']}
                onClick={(e) =>
                    openColorModal2(
                      "max_color",
                      years[k]['max_color'],
                      k
                    )
                }
                onChange={(e) => {}}
                id={'max_color_' + k}
              />
              <TextInput
                label="Max. Value:"
                placeholder="e.g. 10000 (Optional)"
                style={{width:"170px", marginLeft: "12px"}}
                value={years[k]['max_value']}
                onChange={(e) => { years[k]['max_value'] = e.target.value; setYears(years); setUpdateYearColors(!updateYearColors); }}
                id={'max_value_' + k}
              />          
              <Text style={{color:"rgb(77, 85, 102)", marginLeft:"10px", marginTop:"29px"}}><i>*It will get from the CSV if you leave it blank.</i></Text>
            </Box>
            <Box style={{ display: "flex", marginTop: "10px" }}>
              <TextInput
                label="Min. Color:"
                placeholder="Click to select"
                style={{width:"130px"}}
                styles={{input: {backgroundColor:years[k]['min_color']}}}
                value={years[k]['min_color']}
                onClick={(e) =>
                    openColorModal2(
                      "min_color",
                      years[k]['min_color'],
                      k
                    )
                }
                onChange={(e) => {}}
                id={'min_color_' + k}
              />
              <TextInput
                label="Min. Value:"
                placeholder="e.g. 0 (Optional)"
                style={{width:"170px", marginLeft: "12px"}}
                value={years[k]['min_value']}
                onChange={(e) => { years[k]['min_value'] = e.target.value; setYears(years); setUpdateYearColors(!updateYearColors); }}
                id={'min_value_' + k}
              />        
              <Text style={{color:"rgb(77, 85, 102)", marginLeft:"10px", marginTop:"29px"}}><i>*It will get from the CSV if you leave it blank.</i></Text>          
            </Box>
          </Box>
          : ''}
          {(useDifferentColorsP[k] && colorType === 'Group') ? 
          <Box>
            <Text style={{marginTop:"10px", marginBotton:"-5px"}}><b>Group 1:</b></Text>
            <Box style={{ display: "flex", marginTop: "5px" }}>              
              <TextInput
                label="Min. Value:"
                placeholder="e.g. 0"
                style={{width:"170px", marginRight: "12px"}}
                value={(yearGroupsP[el.id] && yearGroupsP[el.id][0]) ? yearGroupsP[el.id][0]['min_value'] : ''}
                onChange={(e) => { if (!yearGroupsP[el.id]) { yearGroupsP[el.id] = []; } if (!yearGroupsP[el.id][0]) { yearGroupsP[el.id][0] = {}; } yearGroupsP[el.id][0]['min_value'] = e.target.value; setYearGroupsP(yearGroupsP); setUpdateYearColors(!updateYearColors); }}
                id={'min_value1_' + k}
              />   
              <TextInput
                label="Max. Value:"
                placeholder="e.g. 10000"
                style={{width:"170px", marginRight: "12px"}}
                value={(yearGroupsP[el.id] && yearGroupsP[el.id][0]) ? yearGroupsP[el.id][0]['max_value'] : ''}
                onChange={(e) => { if (!yearGroupsP[el.id]) { yearGroupsP[el.id] = []; } if (!yearGroupsP[el.id][0]) { yearGroupsP[el.id][0] = {}; } yearGroupsP[el.id][0]['max_value'] = e.target.value; setYearGroupsP(yearGroupsP); setUpdateYearColors(!updateYearColors); }}
                id={'max_value1_' + k}
              />                 
              <TextInput
                label="Color:"
                placeholder="Click to select"
                style={{width:"130px"}}
                styles={{input: {backgroundColor:(yearGroupsP[el.id] && yearGroupsP[el.id][0]) ? yearGroupsP[el.id][0]['color'] : ''}}}
                value={(yearGroupsP[el.id] && yearGroupsP[el.id][0]) ? yearGroupsP[el.id][0]['color'] : ''}
                onClick={(e) =>
                    openColorModal2(
                      "group_color",
                      (yearGroupsP[el.id] && yearGroupsP[el.id][0]) ? yearGroupsP[el.id][0]['color'] : '',
                      el.id,
                      0
                    )
                }
                onChange={(e) => {}}
                id={'group_color1_' + k}
              />
            </Box>
            <Text style={{marginTop:"10px", marginBotton:"-5px"}}><b>Group 2:</b></Text>
            <Box style={{ display: "flex", marginTop: "5px" }}>              
              <TextInput
                label="Min. Value:"
                placeholder="e.g. 0"
                style={{width:"170px", marginRight: "12px"}}
                value={(yearGroupsP[el.id] && yearGroupsP[el.id][1]) ? yearGroupsP[el.id][1]['min_value'] : ''}
                onChange={(e) => { if (!yearGroupsP[el.id]) { yearGroupsP[el.id] = []; } if (!yearGroupsP[el.id][1]) { yearGroupsP[el.id][1] = {}; } yearGroupsP[el.id][1]['min_value'] = e.target.value; setYearGroupsP(yearGroupsP); setUpdateYearColors(!updateYearColors); }}
                id={'min_value2_' + k}
              />   
              <TextInput
                label="Max. Value:"
                placeholder="e.g. 10000"
                style={{width:"170px", marginRight: "12px"}}
                value={(yearGroupsP[el.id] && yearGroupsP[el.id][1]) ? yearGroupsP[el.id][1]['max_value'] : ''}
                onChange={(e) => { if (!yearGroupsP[el.id]) { yearGroupsP[el.id] = []; } if (!yearGroupsP[el.id][1]) { yearGroupsP[el.id][1] = {}; } yearGroupsP[el.id][1]['max_value'] = e.target.value; setYearGroupsP(yearGroupsP); setUpdateYearColors(!updateYearColors); }}
                id={'max_value2_' + k}
              />                 
              <TextInput
                label="Color:"
                placeholder="Click to select"
                style={{width:"130px"}}
                styles={{input: {backgroundColor:(yearGroupsP[el.id] && yearGroupsP[el.id][1]) ? yearGroupsP[el.id][1]['color'] : ''}}}
                value={(yearGroupsP[el.id] && yearGroupsP[el.id][1]) ? yearGroupsP[el.id][1]['color'] : ''}
                onClick={(e) =>
                    openColorModal2(
                      "group_color",
                      (yearGroupsP[el.id] && yearGroupsP[el.id][1]) ? yearGroupsP[el.id][1]['color'] : '',
                      el.id,
                      1
                    )
                }
                onChange={(e) => {}}
                id={'group_color2_' + k}
              />
            </Box>
            <Text style={{marginTop:"10px", marginBotton:"-5px"}}><b>Group 3:</b></Text>
            <Box style={{ display: "flex", marginTop: "5px" }}>              
              <TextInput
                label="Min. Value:"
                placeholder="e.g. 0"
                style={{width:"170px", marginRight: "12px"}}
                value={(yearGroupsP[el.id] && yearGroupsP[el.id][2]) ? yearGroupsP[el.id][2]['min_value'] : ''}
                onChange={(e) => { if (!yearGroupsP[el.id]) { yearGroupsP[el.id] = []; } if (!yearGroupsP[el.id][2]) { yearGroupsP[el.id][2] = {}; } yearGroupsP[el.id][2]['min_value'] = e.target.value; setYearGroupsP(yearGroupsP); setUpdateYearColors(!updateYearColors); }}
                id={'min_value3_' + k}
              />   
              <TextInput
                label="Max. Value:"
                placeholder="e.g. 10000"
                style={{width:"170px", marginRight: "12px"}}
                value={(yearGroupsP[el.id] && yearGroupsP[el.id][2]) ? yearGroupsP[el.id][2]['max_value'] : ''}
                onChange={(e) => { if (!yearGroupsP[el.id]) { yearGroupsP[el.id] = []; } if (!yearGroupsP[el.id][2]) { yearGroupsP[el.id][2] = {}; } yearGroupsP[el.id][2]['max_value'] = e.target.value; setYearGroupsP(yearGroupsP); setUpdateYearColors(!updateYearColors); }}
                id={'max_value3_' + k}
              />                 
              <TextInput
                label="Color:"
                placeholder="Click to select"
                style={{width:"130px"}}
                styles={{input: {backgroundColor:(yearGroupsP[el.id] && yearGroupsP[el.id][2]) ? yearGroupsP[el.id][2]['color'] : ''}}}
                value={(yearGroupsP[el.id] && yearGroupsP[el.id][2]) ? yearGroupsP[el.id][2]['color'] : ''}
                onClick={(e) =>
                    openColorModal2(
                      "group_color",
                      (yearGroupsP[el.id] && yearGroupsP[el.id][2]) ? yearGroupsP[el.id][2]['color'] : '',
                      el.id,
                      2
                    )
                }
                onChange={(e) => {}}
                id={'group_color3_' + k}
              />
            </Box>
            <Text style={{marginTop:"10px", marginBotton:"-5px"}}><b>Group 4:</b></Text>
            <Box style={{ display: "flex", marginTop: "5px" }}>              
              <TextInput
                label="Min. Value:"
                placeholder="e.g. 0"
                style={{width:"170px", marginRight: "12px"}}
                value={(yearGroupsP[el.id] && yearGroupsP[el.id][3]) ? yearGroupsP[el.id][3]['min_value'] : ''}
                onChange={(e) => { if (!yearGroupsP[el.id]) { yearGroupsP[el.id] = []; } if (!yearGroupsP[el.id][3]) { yearGroupsP[el.id][3] = {}; } yearGroupsP[el.id][3]['min_value'] = e.target.value; setYearGroupsP(yearGroupsP); setUpdateYearColors(!updateYearColors); }}
                id={'min_value4_' + k}
              />   
              <TextInput
                label="Max. Value:"
                placeholder="e.g. 10000"
                style={{width:"170px", marginRight: "12px"}}
                value={(yearGroupsP[el.id] && yearGroupsP[el.id][3]) ? yearGroupsP[el.id][3]['max_value'] : ''}
                onChange={(e) => { if (!yearGroupsP[el.id]) { yearGroupsP[el.id] = []; } if (!yearGroupsP[el.id][3]) { yearGroupsP[el.id][3] = {}; } yearGroupsP[el.id][3]['max_value'] = e.target.value; setYearGroupsP(yearGroupsP); setUpdateYearColors(!updateYearColors); }}
                id={'max_value4_' + k}
              />                 
              <TextInput
                label="Color:"
                placeholder="Click to select"
                style={{width:"130px"}}
                styles={{input: {backgroundColor:(yearGroupsP[el.id] && yearGroupsP[el.id][3]) ? yearGroupsP[el.id][3]['color'] : ''}}}
                value={(yearGroupsP[el.id] && yearGroupsP[el.id][3]) ? yearGroupsP[el.id][3]['color'] : ''}
                onClick={(e) =>
                    openColorModal2(
                      "group_color",
                      (yearGroupsP[el.id] && yearGroupsP[el.id][3]) ? yearGroupsP[el.id][3]['color'] : '',
                      el.id,
                      3
                    )
                }
                onChange={(e) => {}}
                id={'group_color4_' + k}
              />
            </Box>
            <Text style={{marginTop:"10px", marginBotton:"-5px"}}><b>Group 5:</b></Text>
            <Box style={{ display: "flex", marginTop: "5px" }}>              
              <TextInput
                label="Min. Value:"
                placeholder="e.g. 0"
                style={{width:"170px", marginRight: "12px"}}
                value={(yearGroupsP[el.id] && yearGroupsP[el.id][4]) ? yearGroupsP[el.id][4]['min_value'] : ''}
                onChange={(e) => { if (!yearGroupsP[el.id]) { yearGroupsP[el.id] = []; } if (!yearGroupsP[el.id][4]) { yearGroupsP[el.id][4] = {}; } yearGroupsP[el.id][4]['min_value'] = e.target.value; setYearGroupsP(yearGroupsP); setUpdateYearColors(!updateYearColors); }}
                id={'min_value5_' + k}
              />   
              <TextInput
                label="Max. Value:"
                placeholder="e.g. 10000"
                style={{width:"170px", marginRight: "12px"}}
                value={(yearGroupsP[el.id] && yearGroupsP[el.id][4]) ? yearGroupsP[el.id][4]['max_value'] : ''}
                onChange={(e) => { if (!yearGroupsP[el.id]) { yearGroupsP[el.id] = []; } if (!yearGroupsP[el.id][4]) { yearGroupsP[el.id][4] = {}; } yearGroupsP[el.id][4]['max_value'] = e.target.value; setYearGroupsP(yearGroupsP); setUpdateYearColors(!updateYearColors); }}
                id={'max_value5_' + k}
              />                 
              <TextInput
                label="Color:"
                placeholder="Click to select"
                style={{width:"130px"}}
                styles={{input: {backgroundColor:(yearGroupsP[el.id] && yearGroupsP[el.id][4]) ? yearGroupsP[el.id][4]['color'] : ''}}}
                value={(yearGroupsP[el.id] && yearGroupsP[el.id][4]) ? yearGroupsP[el.id][4]['color'] : ''}
                onClick={(e) =>
                    openColorModal2(
                      "group_color",
                      (yearGroupsP[el.id] && yearGroupsP[el.id][4]) ? yearGroupsP[el.id][4]['color'] : '',
                      el.id,
                      4
                    )
                }
                onChange={(e) => {}}
                id={'group_color5_' + k}
              />
            </Box>
          </Box> : ''}
          {(useDifferentColorsP[k] && colorType === 'Category') ? categoryColorInputsP[el.id] : ''}
          <Box style={{width:"100%", marginTop:"20px"}}>
            <FileInput
              label="State CSV (Format: ID_ESTADO, VALUE, TITLE, SUBTITLE, ROW1_L, ROW1_R, ROW2_L, ROW2_R, ROW3_L, ROW3_R, IMG1, IMG2):"
              placeholder="Click to select"
              accept=".csv"
              id={'state_csv_' + k}
              style={{ width:"500px" }}
              clearable 
            />
            {el.state_csv &&
            (<div style={{marginTop:'5px'}}>
              <Anchor href={process.env.REACT_APP_CHRONOS_URL + '/csv/' + el.project_id + '_' + el.id + '_state.csv'} style={{paddingTop:'10px', fontSize:"15px"}}>{app.project}_{el.id}_state.csv</Anchor>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{color:"red", cursor:"pointer", fontFamily:"Arial", fontWeight:"Bold"}} onClick={() => deleteFile('state', el.id)}>X</span>
            </div>)}
          </Box>
          <Box style={{display:"none", width:"100%", marginTop:"20px"}}>
            <FileInput
              label="District CSV (Format: ID_ESTADO, ID_DISTRITO_FEDERAL, VALUE, TITLE, SUBTITLE, ROW1_L, ROW1_R, ROW2_L, ROW2_R, ROW3_L, ROW3_R, IMG1, IMG2):"
              placeholder="Click to select"
              accept=".csv"
              id={'district_csv_' + k}
              style={{ width:"500px" }}
              clearable 
            />
            {el.district_csv &&
            (<div style={{marginTop:'5px'}}>
              <Anchor href={process.env.REACT_APP_CHRONOS_URL + '/csv/' + el.project_id + '_' + el.id + '_district.csv'} style={{paddingTop:'10px', fontSize:"15px"}}>{app.project}_{el.id}_district.csv</Anchor>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{color:"red", cursor:"pointer", fontFamily:"Arial", fontWeight:"Bold"}} onClick={() => deleteFile('district', el.id)}>X</span>
            </div>)}
          </Box>
          <Box style={{width:"100%", marginTop:"20px"}}>
            <FileInput
              label="Municipal CSV (Format: ID_ESTADO, ID_MUNICIPIO, VALUE, TITLE, SUBTITLE, ROW1_L, ROW1_R, ROW2_L, ROW2_R, ROW3_L, ROW3_R, IMG1, IMG2):"
              placeholder="Click to select"
              accept=".csv"
              id={'municipal_csv_' + k}
              style={{ width:"500px" }}
              clearable 
            />
            {el.municipal_csv &&
            (<div style={{marginTop:'5px'}}>
              <Anchor href={process.env.REACT_APP_CHRONOS_URL + '/csv/' + el.project_id + '_' + el.id + '_municipal.csv'} style={{paddingTop:'10px', fontSize:"15px"}}>{app.project}_{el.id}_municipal.csv</Anchor>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{color:"red", cursor:"pointer", fontFamily:"Arial", fontWeight:"Bold"}} onClick={() => deleteFile('municipal', el.id)}>X</span>
            </div>)}
          </Box>
          <Box style={{ display: "none", marginTop: "25px", marginBottom: "1px" }}>
            <Text
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "15px",
                fontSize: "15px"
              }}
            >
              Use Different Colors for Municipals:
            </Text>
            <Checkbox
              label=""
              checked={useDifferentColorsMunP[k]}
              onChange={(e) => {setUseDifferentColorsMunPInd(!useDifferentColorsMunP[k], k); setUpdateYearColors(!updateYearColors);}}
              value="Y"
              id={'different_colors_mun_' + k}
            />
          </Box>
          {(useDifferentColorsMunP[k] && (colorType === 'Gradient' || !colorType)) ? 
          <Box>
            <Box style={{ display: "flex", marginTop: "10px" }}>
              <TextInput
                label="Max. Color:"
                placeholder="Click to select"
                style={{width:"130px"}}
                styles={{input: {backgroundColor:years[k]['max_color_mun']}}}
                value={years[k]['max_color_mun']}
                onClick={(e) =>
                    openColorModal2(
                      "max_color_mun",
                      years[k]['max_color_mun'],
                      k
                    )
                }
                onChange={(e) => {}}
                id={'max_color_mun_' + k}
              />
              <TextInput
                label="Max. Value:"
                placeholder="e.g. 10000 (Optional)"
                style={{width:"170px", marginLeft: "12px"}}
                value={years[k]['max_value_mun']}
                onChange={(e) => { years[k]['max_value_mun'] = e.target.value; setYears(years); setUpdateYearColors(!updateYearColors); }}
                id={'max_value_mun_' + k}
              />          
              <Text style={{color:"rgb(77, 85, 102)", marginLeft:"10px", marginTop:"29px"}}><i>*It will get from the CSV if you leave it blank.</i></Text>
            </Box>
            <Box style={{ display: "flex", marginTop: "10px" }}>
              <TextInput
                label="Min. Color:"
                placeholder="Click to select"
                style={{width:"130px"}}
                styles={{input: {backgroundColor:years[k]['min_color_mun']}}}
                value={years[k]['min_color_mun']}
                onClick={(e) =>
                    openColorModal2(
                      "min_color_mun",
                      years[k]['min_color_mun'],
                      k
                    )
                }
                onChange={(e) => {}}
                id={'min_color_mun_' + k}
              />
              <TextInput
                label="Min. Value:"
                placeholder="e.g. 0 (Optional)"
                style={{width:"170px", marginLeft: "12px"}}
                value={years[k]['min_value_mun']}
                onChange={(e) => { years[k]['min_value_mun'] = e.target.value; setYears(years); setUpdateYearColors(!updateYearColors); }}
                id={'min_value_mun_' + k}
              />        
              <Text style={{color:"rgb(77, 85, 102)", marginLeft:"10px", marginTop:"29px"}}><i>*It will get from the CSV if you leave it blank.</i></Text>          
            </Box>
          </Box>
          : ''}
          {(useDifferentColorsMunP[k] && colorType === 'Group') ? 
          <Box>
            <Text style={{marginTop:"10px", marginBotton:"-5px"}}><b>Group 1:</b></Text>
            <Box style={{ display: "flex", marginTop: "5px" }}>              
              <TextInput
                label="Min. Value:"
                placeholder="e.g. 0"
                style={{width:"170px", marginRight: "12px"}}
                value={(yearGroupsMunP[el.id] && yearGroupsMunP[el.id][0]) ? yearGroupsMunP[el.id][0]['min_value'] : ''}
                onChange={(e) => { if (!yearGroupsMunP[el.id]) { yearGroupsMunP[el.id] = []; } if (!yearGroupsMunP[el.id][0]) { yearGroupsMunP[el.id][0] = {}; } yearGroupsMunP[el.id][0]['min_value'] = e.target.value; setYearGroupsMunP(yearGroupsMunP); setUpdateYearColors(!updateYearColors); }}
                id={'min_value1_mun_' + k}
              />   
              <TextInput
                label="Max. Value:"
                placeholder="e.g. 10000"
                style={{width:"170px", marginRight: "12px"}}
                value={(yearGroupsMunP[el.id] && yearGroupsMunP[el.id][0]) ? yearGroupsMunP[el.id][0]['max_value'] : ''}
                onChange={(e) => { if (!yearGroupsMunP[el.id]) { yearGroupsMunP[el.id] = []; } if (!yearGroupsMunP[el.id][0]) { yearGroupsMunP[el.id][0] = {}; } yearGroupsMunP[el.id][0]['max_value'] = e.target.value; setYearGroupsMunP(yearGroupsMunP); setUpdateYearColors(!updateYearColors); }}
                id={'max_value1_mun_' + k}
              />                 
              <TextInput
                label="Color:"
                placeholder="Click to select"
                style={{width:"130px"}}
                styles={{input: {backgroundColor:(yearGroupsMunP[el.id] && yearGroupsMunP[el.id][0]) ? yearGroupsMunP[el.id][0]['color'] : ''}}}
                value={(yearGroupsMunP[el.id] && yearGroupsMunP[el.id][0]) ? yearGroupsMunP[el.id][0]['color'] : ''}
                onClick={(e) =>
                    openColorModal2(
                      "group_color_mun",
                      (yearGroupsMunP[el.id] && yearGroupsMunP[el.id][0]) ? yearGroupsMunP[el.id][0]['color'] : '',
                      el.id,
                      0
                    )
                }
                onChange={(e) => {}}
                id={'group_color1_mun_' + k}
              />
            </Box>
            <Text style={{marginTop:"10px", marginBotton:"-5px"}}><b>Group 2:</b></Text>
            <Box style={{ display: "flex", marginTop: "5px" }}>              
              <TextInput
                label="Min. Value:"
                placeholder="e.g. 0"
                style={{width:"170px", marginRight: "12px"}}
                value={(yearGroupsMunP[el.id] && yearGroupsMunP[el.id][1]) ? yearGroupsMunP[el.id][1]['min_value'] : ''}
                onChange={(e) => { if (!yearGroupsMunP[el.id]) { yearGroupsMunP[el.id] = []; } if (!yearGroupsMunP[el.id][1]) { yearGroupsMunP[el.id][1] = {}; } yearGroupsMunP[el.id][1]['min_value'] = e.target.value; setYearGroupsMunP(yearGroupsMunP); setUpdateYearColors(!updateYearColors); }}
                id={'min_value2_mun_' + k}
              />   
              <TextInput
                label="Max. Value:"
                placeholder="e.g. 10000"
                style={{width:"170px", marginRight: "12px"}}
                value={(yearGroupsMunP[el.id] && yearGroupsMunP[el.id][1]) ? yearGroupsMunP[el.id][1]['max_value'] : ''}
                onChange={(e) => { if (!yearGroupsMunP[el.id]) { yearGroupsMunP[el.id] = []; } if (!yearGroupsMunP[el.id][1]) { yearGroupsMunP[el.id][1] = {}; } yearGroupsMunP[el.id][1]['max_value'] = e.target.value; setYearGroupsMunP(yearGroupsMunP); setUpdateYearColors(!updateYearColors); }}
                id={'max_value2_mun_' + k}
              />                 
              <TextInput
                label="Color:"
                placeholder="Click to select"
                style={{width:"130px"}}
                styles={{input: {backgroundColor:(yearGroupsMunP[el.id] && yearGroupsMunP[el.id][1]) ? yearGroupsMunP[el.id][1]['color'] : ''}}}
                value={(yearGroupsMunP[el.id] && yearGroupsMunP[el.id][1]) ? yearGroupsMunP[el.id][1]['color'] : ''}
                onClick={(e) =>
                    openColorModal2(
                      "group_color_mun",
                      (yearGroupsMunP[el.id] && yearGroupsMunP[el.id][1]) ? yearGroupsMunP[el.id][1]['color'] : '',
                      el.id,
                      1
                    )
                }
                onChange={(e) => {}}
                id={'group_color2_mun_' + k}
              />
            </Box>
            <Text style={{marginTop:"10px", marginBotton:"-5px"}}><b>Group 3:</b></Text>
            <Box style={{ display: "flex", marginTop: "5px" }}>              
              <TextInput
                label="Min. Value:"
                placeholder="e.g. 0"
                style={{width:"170px", marginRight: "12px"}}
                value={(yearGroupsMunP[el.id] && yearGroupsMunP[el.id][2]) ? yearGroupsMunP[el.id][2]['min_value'] : ''}
                onChange={(e) => { if (!yearGroupsMunP[el.id]) { yearGroupsMunP[el.id] = []; } if (!yearGroupsMunP[el.id][2]) { yearGroupsMunP[el.id][2] = {}; } yearGroupsMunP[el.id][2]['min_value'] = e.target.value; setYearGroupsMunP(yearGroupsMunP); setUpdateYearColors(!updateYearColors); }}
                id={'min_value3_mun_' + k}
              />   
              <TextInput
                label="Max. Value:"
                placeholder="e.g. 10000"
                style={{width:"170px", marginRight: "12px"}}
                value={(yearGroupsMunP[el.id] && yearGroupsMunP[el.id][2]) ? yearGroupsMunP[el.id][2]['max_value'] : ''}
                onChange={(e) => { if (!yearGroupsMunP[el.id]) { yearGroupsMunP[el.id] = []; } if (!yearGroupsMunP[el.id][2]) { yearGroupsMunP[el.id][2] = {}; } yearGroupsMunP[el.id][2]['max_value'] = e.target.value; setYearGroupsMunP(yearGroupsMunP); setUpdateYearColors(!updateYearColors); }}
                id={'max_value3_mun_' + k}
              />                 
              <TextInput
                label="Color:"
                placeholder="Click to select"
                style={{width:"130px"}}
                styles={{input: {backgroundColor:(yearGroupsMunP[el.id] && yearGroupsMunP[el.id][2]) ? yearGroupsMunP[el.id][2]['color'] : ''}}}
                value={(yearGroupsMunP[el.id] && yearGroupsMunP[el.id][2]) ? yearGroupsMunP[el.id][2]['color'] : ''}
                onClick={(e) =>
                    openColorModal2(
                      "group_color_mun",
                      (yearGroupsMunP[el.id] && yearGroupsMunP[el.id][2]) ? yearGroupsMunP[el.id][2]['color'] : '',
                      el.id,
                      2
                    )
                }
                onChange={(e) => {}}
                id={'group_color3_mun_' + k}
              />
            </Box>
            <Text style={{marginTop:"10px", marginBotton:"-5px"}}><b>Group 4:</b></Text>
            <Box style={{ display: "flex", marginTop: "5px" }}>              
              <TextInput
                label="Min. Value:"
                placeholder="e.g. 0"
                style={{width:"170px", marginRight: "12px"}}
                value={(yearGroupsMunP[el.id] && yearGroupsMunP[el.id][3]) ? yearGroupsMunP[el.id][3]['min_value'] : ''}
                onChange={(e) => { if (!yearGroupsMunP[el.id]) { yearGroupsMunP[el.id] = []; } if (!yearGroupsMunP[el.id][3]) { yearGroupsMunP[el.id][3] = {}; } yearGroupsMunP[el.id][3]['min_value'] = e.target.value; setYearGroupsMunP(yearGroupsMunP); setUpdateYearColors(!updateYearColors); }}
                id={'min_value4_mun_' + k}
              />   
              <TextInput
                label="Max. Value:"
                placeholder="e.g. 10000"
                style={{width:"170px", marginRight: "12px"}}
                value={(yearGroupsMunP[el.id] && yearGroupsMunP[el.id][3]) ? yearGroupsMunP[el.id][3]['max_value'] : ''}
                onChange={(e) => { if (!yearGroupsMunP[el.id]) { yearGroupsMunP[el.id] = []; } if (!yearGroupsMunP[el.id][3]) { yearGroupsMunP[el.id][3] = {}; } yearGroupsMunP[el.id][3]['max_value'] = e.target.value; setYearGroupsMunP(yearGroupsMunP); setUpdateYearColors(!updateYearColors); }}
                id={'max_value4_mun_' + k}
              />                 
              <TextInput
                label="Color:"
                placeholder="Click to select"
                style={{width:"130px"}}
                styles={{input: {backgroundColor:(yearGroupsMunP[el.id] && yearGroupsMunP[el.id][3]) ? yearGroupsMunP[el.id][3]['color'] : ''}}}
                value={(yearGroupsMunP[el.id] && yearGroupsMunP[el.id][3]) ? yearGroupsMunP[el.id][3]['color'] : ''}
                onClick={(e) =>
                    openColorModal2(
                      "group_color_mun",
                      (yearGroupsMunP[el.id] && yearGroupsMunP[el.id][3]) ? yearGroupsMunP[el.id][3]['color'] : '',
                      el.id,
                      3
                    )
                }
                onChange={(e) => {}}
                id={'group_color4_mun_' + k}
              />
            </Box>
            <Text style={{marginTop:"10px", marginBotton:"-5px"}}><b>Group 5:</b></Text>
            <Box style={{ display: "flex", marginTop: "5px" }}>              
              <TextInput
                label="Min. Value:"
                placeholder="e.g. 0"
                style={{width:"170px", marginRight: "12px"}}
                value={(yearGroupsMunP[el.id] && yearGroupsMunP[el.id][4]) ? yearGroupsMunP[el.id][4]['min_value'] : ''}
                onChange={(e) => { if (!yearGroupsMunP[el.id]) { yearGroupsMunP[el.id] = []; } if (!yearGroupsMunP[el.id][4]) { yearGroupsMunP[el.id][4] = {}; } yearGroupsMunP[el.id][4]['min_value'] = e.target.value; setYearGroupsMunP(yearGroupsMunP); setUpdateYearColors(!updateYearColors); }}
                id={'min_value5_mun_' + k}
              />   
              <TextInput
                label="Max. Value:"
                placeholder="e.g. 10000"
                style={{width:"170px", marginRight: "12px"}}
                value={(yearGroupsMunP[el.id] && yearGroupsMunP[el.id][4]) ? yearGroupsMunP[el.id][4]['max_value'] : ''}
                onChange={(e) => { if (!yearGroupsMunP[el.id]) { yearGroupsMunP[el.id] = []; } if (!yearGroupsMunP[el.id][4]) { yearGroupsMunP[el.id][4] = {}; } yearGroupsMunP[el.id][4]['max_value'] = e.target.value; setYearGroupsMunP(yearGroupsMunP); setUpdateYearColors(!updateYearColors); }}
                id={'max_value5_mun_' + k}
              />                 
              <TextInput
                label="Color:"
                placeholder="Click to select"
                style={{width:"130px"}}
                styles={{input: {backgroundColor:(yearGroupsMunP[el.id] && yearGroupsMunP[el.id][4]) ? yearGroupsMunP[el.id][4]['color'] : ''}}}
                value={(yearGroupsMunP[el.id] && yearGroupsMunP[el.id][4]) ? yearGroupsMunP[el.id][4]['color'] : ''}
                onClick={(e) =>
                    openColorModal2(
                      "group_color_mun",
                      (yearGroupsMunP[el.id] && yearGroupsMunP[el.id][4]) ? yearGroupsMunP[el.id][4]['color'] : '',
                      el.id,
                      4
                    )
                }
                onChange={(e) => {}}
                id={'group_color5_mun_' + k}
              />
            </Box>
          </Box> : ''}
          {(useDifferentColorsMunP[k] && colorType === 'Category') ? categoryColorMunInputsP[el.id] : ''}
        </Box>
      ));
    setRows(yrows);
  }, [years, app.project, deleteFile, deleteYear, useDifferentColorsP, setUseDifferentColorsPInd, useDifferentColorsMunP, setUseDifferentColorsMunPInd, colorType, openColorModal2, updateYearColors, yearGroupsP, yearGroupsMunP, categoryColorInputsP, categoryColorMunInputsP]);
  
  let m = years.length;
  
  const checkIcon = <IconCheck style={{ width: rem(20), height: rem(20) }} />;

  return (
    <Box
      className={"MainBoard"}
      style={{
        width: "100%",
        fontSize: "14px",
        padding: "20px 20px"
      }}
    >
      <Box
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Box style={{ display: "flex" }}>
          <Text
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "15px",
              fontSize: "15px"
            }}
          >
            <b>Project:</b>
          </Text>
          <Select
            placeholder="New Project"
            data={projects}
            defaultValue={app.project}
            value={app.project}
            style={{ marginRight: "25px" }}
            onChange={(e) => changeProject(e)}
            searchable
          />          
          {app.project > 0 && <Anchor href="javscript:void(0);" onClick={() => deleteProject()} style={{color:"red", marginTop:"7px", marginRight:"25px", textDecoration:"underline"}}>Delete</Anchor>}
          <Button onClick={() => addNewProject()}>+ New Project</Button>
        </Box>
        {(app.project > 0) && 
        <Box style={{ display: "flex", marginTop: "25px" }}>
          <Text
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "15px",
              fontSize: "15px"
            }}
          >
            <b>URL:</b>
          </Text><Anchor href={process.env.REACT_APP_CHRONOS_URL + '/?project=' + app.project} target="_blank" style={{ fontSize: "15px" }}>{process.env.REACT_APP_CHRONOS_URL}/?project={app.project}</Anchor>
        </Box>}        
        <Box style={{ display: "flex", marginTop: "20px" }}>
          <TextInput
            label="Name:"
            placeholder="Project Name"
            style={{width:"500px"}}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />          
        </Box>
        <Box style={{ display: "flex", marginTop: "10px" }}>
          <TextInput
            label="Title:"
            placeholder="Top Title"
            style={{width:"500px"}}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Box>
        <Box style={{ display: "flex", marginTop: "15px" }}>
            <Radio.Group
              name="favoriteFramework"
              label="Select Color Type:"
              value={colorType ? colorType : "Gradient"}
            >
              <Group mt="xs" style={{marginTop: "10px", marginBottom: "5px"}}>
                <Radio value="Gradient" label="Gradient" onClick={(e) => setColorType(e.target.value)} />
                <Radio value="Group" label="Group" onClick={(e) => setColorType(e.target.value)} />
                <Radio value="Category" label="Category" onClick={(e) => setColorType(e.target.value)} />
              </Group>
            </Radio.Group>
        </Box>        
        {(colorType === 'Gradient' || !colorType) ? 
        <Box>
            <Box style={{ display: "flex", marginTop: "10px" }}>
              <TextInput
                label="Max. Color:"
                placeholder="Click to select"
                style={{width:"130px"}}
                styles={{input: {backgroundColor:maxColor}}}
                value={maxColor}
                onClick={(e) =>
                    openColorModal2(
                      "max_color",
                      maxColor
                    )
                }
                onChange={(e) => {}}
              />
              <TextInput
                label="Max. Value:"
                placeholder="e.g. 10000 (Optional)"
                style={{width:"170px", marginLeft: "12px"}}
                value={maxValue}
                onChange={(e) => { setMaxValue(e.target.value); }}
              />          
              <Text style={{color:"rgb(77, 85, 102)", marginLeft:"10px", marginTop:"29px"}}><i>*It will get from the CSV if you leave it blank.</i></Text>
            </Box>
            <Box style={{ display: "flex", marginTop: "10px" }}>
              <TextInput
                label="Min. Color:"
                placeholder="Click to select"
                style={{width:"130px"}}
                styles={{input: {backgroundColor:minColor}}}
                value={minColor}
                onClick={(e) =>
                    openColorModal2(
                      "min_color",
                      minColor
                    )
                }
                onChange={(e) => {}}
              />
              <TextInput
                label="Min. Value:"
                placeholder="e.g. 0 (Optional)"
                style={{width:"170px", marginLeft: "12px"}}
                value={minValue}
                onChange={(e) => { setMinValue(e.target.value); }}
              />        
              <Text style={{color:"rgb(77, 85, 102)", marginLeft:"10px", marginTop:"29px"}}><i>*It will get from the CSV if you leave it blank.</i></Text>          
            </Box>
        </Box>
        : ''}
        {(colorType === 'Group') ? 
        <Box>
            <Text style={{marginTop:"10px", marginBotton:"-5px"}}><b>Group 1:</b></Text>
            <Box style={{ display: "flex", marginTop: "5px" }}>              
              <TextInput
                label="Min. Value:"
                placeholder="e.g. 0"
                style={{width:"170px", marginRight: "12px"}}
                value={minValue1}
                onChange={(e) => { setMinValue1(e.target.value); }}
              />   
              <TextInput
                label="Max. Value:"
                placeholder="e.g. 10000"
                style={{width:"170px", marginRight: "12px"}}
                value={maxValue1}
                onChange={(e) => { setMaxValue1(e.target.value); }}
              />                 
              <TextInput
                label="Color:"
                placeholder="Click to select"
                style={{width:"130px"}}
                styles={{input: {backgroundColor:groupColor1}}}
                value={groupColor1}
                onClick={(e) =>
                    openColorModal2(
                      "group_color1",
                      groupColor1
                    )
                }
                onChange={(e) => {}}
              />
            </Box>
            <Text style={{marginTop:"10px", marginBotton:"-5px"}}><b>Group 2:</b></Text>
            <Box style={{ display: "flex", marginTop: "5px" }}>              
              <TextInput
                label="Min. Value:"
                placeholder="e.g. 0"
                style={{width:"170px", marginRight: "12px"}}
                value={minValue2}
                onChange={(e) => { setMinValue2(e.target.value); }}
              />   
              <TextInput
                label="Max. Value:"
                placeholder="e.g. 10000"
                style={{width:"170px", marginRight: "12px"}}
                value={maxValue2}
                onChange={(e) => { setMaxValue2(e.target.value); }}
              />                 
              <TextInput
                label="Color:"
                placeholder="Click to select"
                style={{width:"130px"}}
                styles={{input: {backgroundColor:groupColor2}}}
                value={groupColor2}
                onClick={(e) =>
                    openColorModal2(
                      "group_color2",
                      groupColor2
                    )
                }
                onChange={(e) => {}}
              />
            </Box>
            <Text style={{marginTop:"10px", marginBotton:"-5px"}}><b>Group 3:</b></Text>
            <Box style={{ display: "flex", marginTop: "5px" }}>              
              <TextInput
                label="Min. Value:"
                placeholder="e.g. 0"
                style={{width:"170px", marginRight: "12px"}}
                value={minValue3}
                onChange={(e) => { setMinValue3(e.target.value); }}
              />   
              <TextInput
                label="Max. Value:"
                placeholder="e.g. 10000"
                style={{width:"170px", marginRight: "12px"}}
                value={maxValue3}
                onChange={(e) => { setMaxValue3(e.target.value); }}
              />                 
              <TextInput
                label="Color:"
                placeholder="Click to select"
                style={{width:"130px"}}
                styles={{input: {backgroundColor:groupColor3}}}
                value={groupColor3}
                onClick={(e) =>
                    openColorModal2(
                      "group_color3",
                      groupColor3
                    )
                }
                onChange={(e) => {}}
              />
            </Box>
            <Text style={{marginTop:"10px", marginBotton:"-5px"}}><b>Group 4:</b></Text>
            <Box style={{ display: "flex", marginTop: "5px" }}>              
              <TextInput
                label="Min. Value:"
                placeholder="e.g. 0"
                style={{width:"170px", marginRight: "12px"}}
                value={minValue4}
                onChange={(e) => { setMinValue4(e.target.value); }}
              />   
              <TextInput
                label="Max. Value:"
                placeholder="e.g. 10000"
                style={{width:"170px", marginRight: "12px"}}
                value={maxValue4}
                onChange={(e) => { setMaxValue4(e.target.value); }}
              />                 
              <TextInput
                label="Color:"
                placeholder="Click to select"
                style={{width:"130px"}}
                styles={{input: {backgroundColor:groupColor4}}}
                value={groupColor4}
                onClick={(e) =>
                    openColorModal2(
                      "group_color4",
                      groupColor5
                    )
                }
                onChange={(e) => {}}
              />
            </Box>
            <Text style={{marginTop:"10px", marginBotton:"-5px"}}><b>Group 5:</b></Text>
            <Box style={{ display: "flex", marginTop: "5px" }}>              
              <TextInput
                label="Min. Value:"
                placeholder="e.g. 0"
                style={{width:"170px", marginRight: "12px"}}
                value={minValue5}
                onChange={(e) => { setMinValue5(e.target.value); }}
              />   
              <TextInput
                label="Max. Value:"
                placeholder="e.g. 10000"
                style={{width:"170px", marginRight: "12px"}}
                value={maxValue5}
                onChange={(e) => { setMaxValue5(e.target.value); }}
              />                 
              <TextInput
                label="Color:"
                placeholder="Click to select"
                style={{width:"130px"}}
                styles={{input: {backgroundColor:groupColor5}}}
                value={groupColor5}
                onClick={(e) =>
                    openColorModal2(
                      "group_color5",
                      groupColor5
                    )
                }
                onChange={(e) => {}}
              />
            </Box>
        </Box> : ''}
        {(colorType === 'Category') ? categoryColorInputs : ''}
        <Box style={{ display: "flex", marginTop: "25px", marginBottom: "1px" }}>
            <Text
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "15px",
                fontSize: "15px"
              }}
            >
              Show Popups:
            </Text>
            <Checkbox
              label=""
              checked={showPopup}
              onChange={(e) => {setShowPopup(!showPopup);}}
              value="Y"
            />
        </Box>
        <Box style={{ display: "flex", marginTop: "20px", marginBottom: "1px" }}>
            <Text
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "15px",
                fontSize: "15px"
              }}
            >
              Cover Images:
            </Text>            
        </Box>
        <Box style={{dispaly:"flex", width:"100%", marginTop:"0px"}}>
            <FileInput
              label=""
              placeholder="Click to select"
              accept="image/*"
              id={'images'}
              style={{ width:"500px" }}
              clearable 
              multiple 
            />
        </Box>
        <Box style={{display:"flex", flexDirection:"Row", marginTop:"20px", marginBottom:"5px"}}>
          {imgRows}
        </Box>
        <Box id={'slides'} style={{visibility:'hidden'}}>
            <Box style={{position: "fixed", zIndex: "99998", backgroundColor:"rgba(0, 0, 0, 0.8)", width:"120%", height:"120%", left:"-20%", top:"-20%"}} onClick={() => {document.getElementById('slides').style.visibility = 'hidden';}}>
            </Box>
            <Box style={{position: "fixed", zIndex: "99999", top:"10vh"}}>
              <Carousel style={{height:"80vh", width:"80vw", maxWidth:"80vw"}} maw={320} mx="auto" withIndicators={true} loop={true} height={200} align="start">
                {imgSlides}
              </Carousel>
            </Box>
        </Box>
        <Box style={{ display: "flex", marginTop: "25px", marginBottom: "1px" }}>
          <Button onClick={() => saveProject()}>Save Project</Button>
        </Box>
        {rows}
        {app.project > 0 && (years.length === 0 || newYear) && (<Box key={'box_' + m} style={{ marginTop: "20px", paddingTop: "15px", borderTop: "solid 1px #999999" }}>
          <Box style={{display: "flex", width:"100%"}}>
            <Text
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "15px",
                fontSize: "15px"
              }}
            >
              <b>Year:</b>
            </Text>
            <TextInput
              className={"yearInput"}
              placeholder="Year"
              style={{width:"200px"}}
              defaultValue={''}
              id={'year_' + m}
              key={''}
            />
            <Anchor href="javscript:void(0);" onClick={() => deleteNewYear()} style={{color:"red", marginTop:"7px", marginLeft:"25px", textDecoration:"underline"}}>Delete</Anchor>
            <input type="hidden" id={'year_id_' + m} value={0} />
          </Box>
          <Box style={{ display: "none", marginTop: "25px", marginBottom: "1px" }}>
            <Text
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "15px",
                fontSize: "15px"
              }}
            >
              Use Different Colors:
            </Text>
            <Checkbox
              label=""
              defaultChecked={useDifferentColors}
              onChange={(e) => {setUseDifferentColors(!useDifferentColors);}}
              value="Y"
              id={'different_colors_' + m}
            />
          </Box>
          {(useDifferentColors && (colorType === 'Gradient' || !colorType)) ? 
          <Box>
            <Box style={{ display: "flex", marginTop: "10px" }}>
              <TextInput
                label="Max. Color:"
                placeholder="Click to select"
                style={{width:"130px"}}
                styles={{input: {backgroundColor:yearMaxColor}}}
                value={yearMaxColor}
                onClick={(e) =>
                    openColorModal2(
                      "year_max_color",
                      yearMaxColor
                    )
                }
                onChange={(e) => {}}
                id={'max_color_' + m}
              />
              <TextInput
                label="Max. Value:"
                placeholder="e.g. 10000 (Optional)"
                style={{width:"170px", marginLeft: "12px"}}
                value={yearMaxValue}
                onChange={(e) => { setYearMaxValue(e.target.value); }}
                id={'max_value_' + m}
              />          
              <Text style={{color:"rgb(77, 85, 102)", marginLeft:"10px", marginTop:"29px"}}><i>*It will get from the CSV if you leave it blank.</i></Text>
            </Box>
            <Box style={{ display: "flex", marginTop: "10px" }}>
              <TextInput
                label="Min. Color:"
                placeholder="Click to select"
                style={{width:"130px"}}
                styles={{input: {backgroundColor:yearMinColor}}}
                value={yearMinColor}
                onClick={(e) =>
                    openColorModal2(
                      "year_min_color",
                      yearMinColor
                    )
                }
                onChange={(e) => {}}
                id={'min_color_' + m}
              />
              <TextInput
                label="Min. Value:"
                placeholder="e.g. 0 (Optional)"
                style={{width:"170px", marginLeft: "12px"}}
                value={yearMinValue}
                onChange={(e) => { setYearMinValue(e.target.value); }}
                id={'min_value_' + m}
              />        
              <Text style={{color:"rgb(77, 85, 102)", marginLeft:"10px", marginTop:"29px"}}><i>*It will get from the CSV if you leave it blank.</i></Text>          
            </Box>
          </Box>
          : ''}
          {(useDifferentColors && colorType === 'Group') ? 
          <Box>
            <Text style={{marginTop:"10px", marginBotton:"-5px"}}><b>Group 1:</b></Text>
            <Box style={{ display: "flex", marginTop: "5px" }}>              
              <TextInput
                label="Min. Value:"
                placeholder="e.g. 0"
                style={{width:"170px", marginRight: "12px"}}
                value={yearGroups[0] ? yearGroups[0]['min_value'] : ''}
                onChange={(e) => { if (!yearGroups[0]) { yearGroups[0] = {}; } yearGroups[0]['min_value'] = e.target.value; setYearGroups(yearGroups); setUpdateYearColors(!updateYearColors); }}
                id={'min_value1_' + m}
              />   
              <TextInput
                label="Max. Value:"
                placeholder="e.g. 10000"
                style={{width:"170px", marginRight: "12px"}}
                value={yearGroups[0] ? yearGroups[0]['max_value'] : ''}
                onChange={(e) => { if (!yearGroups[0]) { yearGroups[0] = {}; } yearGroups[0]['max_value'] = e.target.value; setYearGroups(yearGroups); setUpdateYearColors(!updateYearColors); }}
                id={'max_value1_' + m}
              />                 
              <TextInput
                label="Color:"
                placeholder="Click to select"
                style={{width:"130px"}}
                styles={{input: {backgroundColor:yearGroups[0] ? yearGroups[0]['color'] : ''}}}
                value={yearGroups[0] ? yearGroups[0]['color'] : ''}
                onClick={(e) =>
                    openColorModal2(
                      "group_color",
                      yearGroups[0] ? yearGroups[0]['color'] : '',
                      0
                    )
                }
                onChange={(e) => {}}
                id={'group_color1_' + m}
              />
            </Box>
            <Text style={{marginTop:"10px", marginBotton:"-5px"}}><b>Group 2:</b></Text>
            <Box style={{ display: "flex", marginTop: "5px" }}>              
              <TextInput
                label="Min. Value:"
                placeholder="e.g. 0"
                style={{width:"170px", marginRight: "12px"}}
                value={yearGroups[1] ? yearGroups[1]['min_value'] : ''}
                onChange={(e) => { if (!yearGroups[1]) { yearGroups[1] = {}; } yearGroups[1]['min_value'] = e.target.value; setYearGroups(yearGroups); setUpdateYearColors(!updateYearColors); }}
                id={'min_value2_' + m}
              />   
              <TextInput
                label="Max. Value:"
                placeholder="e.g. 10000"
                style={{width:"170px", marginRight: "12px"}}
                value={yearGroups[1] ? yearGroups[1]['max_value'] : ''}
                onChange={(e) => { if (!yearGroups[1]) { yearGroups[1] = {}; } yearGroups[1]['max_value'] = e.target.value; setYearGroups(yearGroups); setUpdateYearColors(!updateYearColors); }}
                id={'max_value2_' + m}
              />                 
              <TextInput
                label="Color:"
                placeholder="Click to select"
                style={{width:"130px"}}
                styles={{input: {backgroundColor:yearGroups[1] ? yearGroups[1]['color'] : ''}}}
                value={yearGroups[1] ? yearGroups[1]['color'] : ''}
                onClick={(e) =>
                    openColorModal2(
                      "group_color",
                      yearGroups[1] ? yearGroups[1]['color'] : '',
                      1
                    )
                }
                onChange={(e) => {}}
                id={'group_color2_' + m}
              />
            </Box>
            <Text style={{marginTop:"10px", marginBotton:"-5px"}}><b>Group 3:</b></Text>
            <Box style={{ display: "flex", marginTop: "5px" }}>              
              <TextInput
                label="Min. Value:"
                placeholder="e.g. 0"
                style={{width:"170px", marginRight: "12px"}}
                vvalue={yearGroups[2] ? yearGroups[2]['min_value'] : ''}
                onChange={(e) => { if (!yearGroups[2]) { yearGroups[2] = {}; } yearGroups[2]['min_value'] = e.target.value; setYearGroups(yearGroups); setUpdateYearColors(!updateYearColors); }}
                id={'min_value3_' + m}
              />   
              <TextInput
                label="Max. Value:"
                placeholder="e.g. 10000"
                style={{width:"170px", marginRight: "12px"}}
                value={yearGroups[2] ? yearGroups[2]['max_value'] : ''}
                onChange={(e) => { if (!yearGroups[2]) { yearGroups[2] = {}; } yearGroups[2]['max_value'] = e.target.value; setYearGroups(yearGroups); setUpdateYearColors(!updateYearColors); }}
                id={'max_value3_' + m}
              />                 
              <TextInput
                label="Color:"
                placeholder="Click to select"
                style={{width:"130px"}}
                styles={{input: {backgroundColor:yearGroups[2] ? yearGroups[2]['color'] : ''}}}
                value={yearGroups[2] ? yearGroups[2]['color'] : ''}
                onClick={(e) =>
                    openColorModal2(
                      "group_color",
                      yearGroups[2] ? yearGroups[2]['color'] : '',
                      2
                    )
                }
                onChange={(e) => {}}
                id={'group_color3_' + m}
              />
            </Box>
            <Text style={{marginTop:"10px", marginBotton:"-5px"}}><b>Group 4:</b></Text>
            <Box style={{ display: "flex", marginTop: "5px" }}>              
              <TextInput
                label="Min. Value:"
                placeholder="e.g. 0"
                style={{width:"170px", marginRight: "12px"}}
                value={yearGroups[3] ? yearGroups[3]['min_value'] : ''}
                onChange={(e) => { if (!yearGroups[3]) { yearGroups[3] = {}; } yearGroups[3]['min_value'] = e.target.value; setYearGroups(yearGroups); setUpdateYearColors(!updateYearColors); }}
                id={'min_value4_' + m}
              />   
              <TextInput
                label="Max. Value:"
                placeholder="e.g. 10000"
                style={{width:"170px", marginRight: "12px"}}
                value={yearGroups[3] ? yearGroups[3]['max_value'] : ''}
                onChange={(e) => { if (!yearGroups[3]) { yearGroups[3] = {}; } yearGroups[3]['max_value'] = e.target.value; setYearGroups(yearGroups); setUpdateYearColors(!updateYearColors); }}
                id={'max_value4_' + m}
              />                 
              <TextInput
                label="Color:"
                placeholder="Click to select"
                style={{width:"130px"}}
                styles={{input: {backgroundColor:yearGroups[3] ? yearGroups[3]['color'] : ''}}}
                value={yearGroups[3] ? yearGroups[3]['color'] : ''}
                onClick={(e) =>
                    openColorModal2(
                      "group_color",
                      yearGroups[3] ? yearGroups[3]['color'] : '',
                      3
                    )
                }
                onChange={(e) => {}}
                id={'group_color4_' + m}
              />
            </Box>
            <Text style={{marginTop:"10px", marginBotton:"-5px"}}><b>Group 5:</b></Text>
            <Box style={{ display: "flex", marginTop: "5px" }}>              
              <TextInput
                label="Min. Value:"
                placeholder="e.g. 0"
                style={{width:"170px", marginRight: "12px"}}
                value={yearGroups[4] ? yearGroups[4]['min_value'] : ''}
                onChange={(e) => { if (!yearGroups[4]) { yearGroups[4] = {}; } yearGroups[4]['min_value'] = e.target.value; setYearGroups(yearGroups); setUpdateYearColors(!updateYearColors); }}
                id={'min_value5_' + m}
              />   
              <TextInput
                label="Max. Value:"
                placeholder="e.g. 10000"
                style={{width:"170px", marginRight: "12px"}}
                value={yearGroups[4] ? yearGroups[4]['max_value'] : ''}
                onChange={(e) => { if (!yearGroups[4]) { yearGroups[4] = {}; } yearGroups[4]['max_value'] = e.target.value; setYearGroups(yearGroups); setUpdateYearColors(!updateYearColors); }}
                id={'max_value5_' + m}
              />                 
              <TextInput
                label="Color:"
                placeholder="Click to select"
                style={{width:"130px"}}
                styles={{input: {backgroundColor:yearGroups[4] ? yearGroups[4]['color'] : ''}}}
                value={yearGroups[4] ? yearGroups[4]['color'] : ''}
                onClick={(e) =>
                    openColorModal2(
                      "group_color",
                      yearGroups[4] ? yearGroups[4]['color'] : '',
                      4
                    )
                }
                onChange={(e) => {}}
                id={'group_color5_' + m}
              />
            </Box>
          </Box> : ''}
          {(useDifferentColors && colorType === 'Category') ? categoryColorInputsN : ''}
          <Box style={{width:"100%", marginTop:"20px"}}>
            <FileInput
              label="State CSV (Format: ID_ESTADO, VALUE, TITLE, SUBTITLE, ROW1_L, ROW1_R, ROW2_L, ROW2_R, ROW3_L, ROW3_R, IMG1, IMG2):"
              placeholder="Click to select"
              accept=".csv"
              id={'state_csv_' + m}
              style={{ width:"500px" }}
              clearable 
            />
          </Box>
          <Box style={{display:"none", width:"100%", marginTop:"20px"}}>
            <FileInput
              label="District CSV (Format: ID_ESTADO, ID_DISTRITO_FEDERAL, VALUE, TITLE, SUBTITLE, ROW1_L, ROW1_R, ROW2_L, ROW2_R, ROW3_L, ROW3_R, IMG1, IMG2):"
              placeholder="Click to select"
              accept=".csv"
              id={'district_csv_' + m}
              style={{ width:"500px" }}
              clearable 
            />
          </Box>
          <Box style={{width:"100%", marginTop:"20px"}}>
            <FileInput
              label="Municipal CSV (Format: ID_ESTADO, ID_MUNICIPIO, VALUE, TITLE, SUBTITLE, ROW1_L, ROW1_R, ROW2_L, ROW2_R, ROW3_L, ROW3_R, IMG1, IMG2):"
              placeholder="Click to select"
              accept=".csv"
              id={'municipal_csv_' + m}
              style={{ width:"500px" }}
              clearable 
            />
          </Box>
          <Box style={{ display: "none", marginTop: "25px", marginBottom: "1px" }}>
            <Text
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "15px",
                fontSize: "15px"
              }}
            >
              Use Different Colors for Municipals:
            </Text>
            <Checkbox
              label=""
              defaultChecked={useDifferentColorsMun}
              onChange={(e) => {setUseDifferentColorsMun(!useDifferentColorsMun);}}
              value="Y"
              id={'different_colors_mun_' + m}
            />
          </Box>
          {(useDifferentColorsMun && (colorType === 'Gradient' || !colorType)) ? 
          <Box>
            <Box style={{ display: "flex", marginTop: "10px" }}>
              <TextInput
                label="Max. Color:"
                placeholder="Click to select"
                style={{width:"130px"}}
                styles={{input: {backgroundColor:yearMaxColorMun}}}
                value={yearMaxColorMun}
                onClick={(e) =>
                    openColorModal2(
                      "year_max_color_mun",
                      yearMaxColorMun
                    )
                }
                onChange={(e) => {}}
                id={'max_color_mun_' + m}
              />
              <TextInput
                label="Max. Value:"
                placeholder="e.g. 10000 (Optional)"
                style={{width:"170px", marginLeft: "12px"}}
                value={yearMaxValueMun}
                onChange={(e) => { setYearMaxValueMun(e.target.value); }}
                id={'max_value_mun_' + m}
              />          
              <Text style={{color:"rgb(77, 85, 102)", marginLeft:"10px", marginTop:"29px"}}><i>*It will get from the CSV if you leave it blank.</i></Text>
            </Box>
            <Box style={{ display: "flex", marginTop: "10px" }}>
              <TextInput
                label="Min. Color:"
                placeholder="Click to select"
                style={{width:"130px"}}
                styles={{input: {backgroundColor:yearMinColorMun}}}
                value={yearMinColorMun}
                onClick={(e) =>
                    openColorModal2(
                      "year_min_color_mun",
                      yearMinColorMun
                    )
                }
                onChange={(e) => {}}
                id={'min_color_mun_' + m}
              />
              <TextInput
                label="Min. Value:"
                placeholder="e.g. 0 (Optional)"
                style={{width:"170px", marginLeft: "12px"}}
                value={yearMinValueMun}
                onChange={(e) => { setYearMinValueMun(e.target.value); }}
                id={'min_value_mun_' + m}
              />        
              <Text style={{color:"rgb(77, 85, 102)", marginLeft:"10px", marginTop:"29px"}}><i>*It will get from the CSV if you leave it blank.</i></Text>          
            </Box>
          </Box>
          : ''}
          {(useDifferentColorsMun && colorType === 'Group') ? 
          <Box>
            <Text style={{marginTop:"10px", marginBotton:"-5px"}}><b>Group 1:</b></Text>
            <Box style={{ display: "flex", marginTop: "5px" }}>              
              <TextInput
                label="Min. Value:"
                placeholder="e.g. 0"
                style={{width:"170px", marginRight: "12px"}}
                value={yearGroupsMun[0] ? yearGroupsMun[0]['min_value'] : ''}
                onChange={(e) => { if (!yearGroupsMun[0]) { yearGroupsMun[0] = {}; } yearGroupsMun[0]['min_value'] = e.target.value; setYearGroupsMun(yearGroupsMun); setUpdateYearColors(!updateYearColors); }}
                id={'min_value1_mun_' + m}
              />   
              <TextInput
                label="Max. Value:"
                placeholder="e.g. 10000"
                style={{width:"170px", marginRight: "12px"}}
                value={yearGroupsMun[0] ? yearGroupsMun[0]['max_value'] : ''}
                onChange={(e) => { if (!yearGroupsMun[0]) { yearGroupsMun[0] = {}; } yearGroupsMun[0]['max_value'] = e.target.value; setYearGroupsMun(yearGroupsMun); setUpdateYearColors(!updateYearColors); }}
                id={'max_value1_mun_' + m}
              />                 
              <TextInput
                label="Color:"
                placeholder="Click to select"
                style={{width:"130px"}}
                styles={{input: {backgroundColor:yearGroupsMun[0] ? yearGroupsMun[0]['color'] : ''}}}
                value={yearGroupsMun[0] ? yearGroupsMun[0]['color'] : ''}
                onClick={(e) =>
                    openColorModal2(
                      "group_color_mun",
                      yearGroupsMun[0] ? yearGroupsMun[0]['color'] : '',
                      0
                    )
                }
                onChange={(e) => {}}
                id={'group_color1_mun_' + m}
              />
            </Box>
            <Text style={{marginTop:"10px", marginBotton:"-5px"}}><b>Group 2:</b></Text>
            <Box style={{ display: "flex", marginTop: "5px" }}>              
              <TextInput
                label="Min. Value:"
                placeholder="e.g. 0"
                style={{width:"170px", marginRight: "12px"}}
                value={yearGroupsMun[1] ? yearGroupsMun[1]['min_value'] : ''}
                onChange={(e) => { if (!yearGroupsMun[1]) { yearGroupsMun[1] = {}; } yearGroupsMun[1]['min_value'] = e.target.value; setYearGroupsMun(yearGroupsMun); setUpdateYearColors(!updateYearColors); }}
                id={'min_value2_mun_' + m}
              />   
              <TextInput
                label="Max. Value:"
                placeholder="e.g. 10000"
                style={{width:"170px", marginRight: "12px"}}
                value={yearGroupsMun[1] ? yearGroupsMun[1]['max_value'] : ''}
                onChange={(e) => { if (!yearGroupsMun[1]) { yearGroupsMun[1] = {}; } yearGroupsMun[1]['max_value'] = e.target.value; setYearGroupsMun(yearGroupsMun); setUpdateYearColors(!updateYearColors); }}
                id={'max_value2_mun_' + m}
              />                 
              <TextInput
                label="Color:"
                placeholder="Click to select"
                style={{width:"130px"}}
                styles={{input: {backgroundColor:yearGroupsMun[1] ? yearGroupsMun[1]['color'] : ''}}}
                value={yearGroupsMun[1] ? yearGroupsMun[1]['color'] : ''}
                onClick={(e) =>
                    openColorModal2(
                      "group_color_mun",
                      yearGroupsMun[1] ? yearGroupsMun[1]['color'] : '',
                      1
                    )
                }
                onChange={(e) => {}}
                id={'group_color2_mun_' + m}
              />
            </Box>
            <Text style={{marginTop:"10px", marginBotton:"-5px"}}><b>Group 3:</b></Text>
            <Box style={{ display: "flex", marginTop: "5px" }}>              
              <TextInput
                label="Min. Value:"
                placeholder="e.g. 0"
                style={{width:"170px", marginRight: "12px"}}
                vvalue={yearGroupsMun[2] ? yearGroupsMun[2]['min_value'] : ''}
                onChange={(e) => { if (!yearGroupsMun[2]) { yearGroupsMun[2] = {}; } yearGroupsMun[2]['min_value'] = e.target.value; setYearGroupsMun(yearGroupsMun); setUpdateYearColors(!updateYearColors); }}
                id={'min_value3_mun_' + m}
              />   
              <TextInput
                label="Max. Value:"
                placeholder="e.g. 10000"
                style={{width:"170px", marginRight: "12px"}}
                value={yearGroupsMun[2] ? yearGroupsMun[2]['max_value'] : ''}
                onChange={(e) => { if (!yearGroupsMun[2]) { yearGroupsMun[2] = {}; } yearGroupsMun[2]['max_value'] = e.target.value; setYearGroupsMun(yearGroupsMun); setUpdateYearColors(!updateYearColors); }}
                id={'max_value3_mun_' + m}
              />                 
              <TextInput
                label="Color:"
                placeholder="Click to select"
                style={{width:"130px"}}
                styles={{input: {backgroundColor:yearGroupsMun[2] ? yearGroupsMun[2]['color'] : ''}}}
                value={yearGroupsMun[2] ? yearGroupsMun[2]['color'] : ''}
                onClick={(e) =>
                    openColorModal2(
                      "group_color_mun",
                      yearGroupsMun[2] ? yearGroupsMun[2]['color'] : '',
                      2
                    )
                }
                onChange={(e) => {}}
                id={'group_color3_mun_' + m}
              />
            </Box>
            <Text style={{marginTop:"10px", marginBotton:"-5px"}}><b>Group 4:</b></Text>
            <Box style={{ display: "flex", marginTop: "5px" }}>              
              <TextInput
                label="Min. Value:"
                placeholder="e.g. 0"
                style={{width:"170px", marginRight: "12px"}}
                value={yearGroupsMun[3] ? yearGroupsMun[3]['min_value'] : ''}
                onChange={(e) => { if (!yearGroupsMun[3]) { yearGroupsMun[3] = {}; } yearGroupsMun[3]['min_value'] = e.target.value; setYearGroupsMun(yearGroupsMun); setUpdateYearColors(!updateYearColors); }}
                id={'min_value4_mun_' + m}
              />   
              <TextInput
                label="Max. Value:"
                placeholder="e.g. 10000"
                style={{width:"170px", marginRight: "12px"}}
                value={yearGroupsMun[3] ? yearGroupsMun[3]['max_value'] : ''}
                onChange={(e) => { if (!yearGroupsMun[3]) { yearGroupsMun[3] = {}; } yearGroupsMun[3]['max_value'] = e.target.value; setYearGroupsMun(yearGroupsMun); setUpdateYearColors(!updateYearColors); }}
                id={'max_value4_mun_' + m}
              />                 
              <TextInput
                label="Color:"
                placeholder="Click to select"
                style={{width:"130px"}}
                styles={{input: {backgroundColor:yearGroupsMun[3] ? yearGroupsMun[3]['color'] : ''}}}
                value={yearGroupsMun[3] ? yearGroupsMun[3]['color'] : ''}
                onClick={(e) =>
                    openColorModal2(
                      "group_color_mun",
                      yearGroupsMun[3] ? yearGroupsMun[3]['color'] : '',
                      3
                    )
                }
                onChange={(e) => {}}
                id={'group_color4_mun_' + m}
              />
            </Box>
            <Text style={{marginTop:"10px", marginBotton:"-5px"}}><b>Group 5:</b></Text>
            <Box style={{ display: "flex", marginTop: "5px" }}>              
              <TextInput
                label="Min. Value:"
                placeholder="e.g. 0"
                style={{width:"170px", marginRight: "12px"}}
                value={yearGroupsMun[4] ? yearGroupsMun[4]['min_value'] : ''}
                onChange={(e) => { if (!yearGroupsMun[4]) { yearGroupsMun[4] = {}; } yearGroupsMun[4]['min_value'] = e.target.value; setYearGroupsMun(yearGroupsMun); setUpdateYearColors(!updateYearColors); }}
                id={'min_value5_mun_' + m}
              />   
              <TextInput
                label="Max. Value:"
                placeholder="e.g. 10000"
                style={{width:"170px", marginRight: "12px"}}
                value={yearGroupsMun[4] ? yearGroupsMun[4]['max_value'] : ''}
                onChange={(e) => { if (!yearGroupsMun[4]) { yearGroupsMun[4] = {}; } yearGroupsMun[4]['max_value'] = e.target.value; setYearGroupsMun(yearGroupsMun); setUpdateYearColors(!updateYearColors); }}
                id={'max_value5_mun_' + m}
              />                 
              <TextInput
                label="Color:"
                placeholder="Click to select"
                style={{width:"130px"}}
                styles={{input: {backgroundColor:yearGroupsMun[4] ? yearGroupsMun[4]['color'] : ''}}}
                value={yearGroupsMun[4] ? yearGroupsMun[4]['color'] : ''}
                onClick={(e) =>
                    openColorModal2(
                      "group_color_mun",
                      yearGroupsMun[4] ? yearGroupsMun[4]['color'] : '',
                      4
                    )
                }
                onChange={(e) => {}}
                id={'group_color5_mun_' + m}
              />
            </Box>
          </Box> : ''}
          {(useDifferentColorsMun && colorType === 'Category') ? categoryColorMunInputsN : ''}
        </Box>)}
        {app.project > 0 && (<Box style={{ display: "flex", marginTop: "25px", marginBottom: "100px" }}>
          <Button onClick={() => saveYears()}>Save Year(s)</Button>
          {m > 0 && !newYear && (<Button onClick={() => addNewYear()} style={{marginLeft:"270px"}}>+ New Year</Button>)}
        </Box>)}
      </Box>

      <ColorModal
        open={showColorModal}
        onClose={onCloseColorModal}
        selectedColorType={selectedColorType}
        selectedColor={selectedColor}
        onSuccess={onSuccessColorModal}
      />
      
      {savedYears && (<Notification icon={checkIcon} color="teal" title="Years saved successfully!" mt="md" style={{position:"fixed", top:"35px", right:"20px", border:"solid 1px #aaaaaa", width:"320px"}}>
      </Notification>)}
      
      {savedProject && (<Notification icon={checkIcon} color="teal" title="Project saved successfully!" mt="md" style={{position:"fixed", top:"35px", right:"20px", border:"solid 1px #aaaaaa", width:"320px"}}>
      </Notification>)}
    </Box>
  );
};

export default MainBoard;
